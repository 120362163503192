import React, { useEffect } from 'react'
import { GoogleTranslate } from '../components'
import { IconoZabala } from '.././resources'

import { useTranslation } from 'react-i18next'
import { registrar_evento } from '../contexts/analytics'

const spanStyle = {
    border: "0px",
    clip: "rect(0px, 0px, 0px, 0px)",
    height: "1px",
    margin: "-1px",
    overflow: "hidden",
    padding: "0px",
    position: "fixed",
    whiteSpace: "nowrap",
    width: "1px",
    top: "0px",
    left: "0px"
}


export default function BigGallery({images}) {

    const { t } = useTranslation()

    const [index, setIndex] = React.useState(0);
    const [open, setOpen] = React.useState(false);

    const handleNext = () => {
        if (index === images.length - 1) {
            setIndex(0)
        } else {
            setIndex(index + 1)
        }
    }

    const handlePrev = () => {
        if (index === 0) {
            setIndex(images.length - 1)
        } else {
            setIndex(index - 1)
        }
    }

    return (
        <>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {
                    images.map((img, index) => {  
                        return (
                            <div key={index} className='relative group' onClick={() => {
                                            setIndex(index);
                                            setOpen(true);
                                            registrar_evento("Abrir Imagen Galeria")
                                        }
                                    }>
                                <img 
                                    className="h-auto max-w-full rounded-lg cursor-pointer" 
                                    src={img.src} 
                                    alt={img.alt}
                                />
                                <p 
                                    className='absolute top-0 w-full h-full group-hover:flex items-center cursor-pointer justify-center text-3xl text-white font-bold bg-[#ffffff4c] backdrop-blur-sm hidden'
                                >{t("see_more")}</p>
                            </div>
                        )
                    })
                }
            </div>
            {/*
            <dialog open={open} style={{position: "fixed", overflow: "auto", inset: "0px"}} className='relative z-30 hover:shadow-md rounded-lg border-[2px] shadow transition-all'>
                <div className='absolute w-full bg-[#ffffff8a] backdrop-blur-lg'>
                    <div className="relative flex items-center justify-between pl-5 h-[50px]">
                        <div className='flex gap-5'>
                            <img src={IconoZabala} alt='Logo Luarca Zabala Hotel' className='h-[50px] py-2' />   
                            <span className='flex items-center text-secondary'>{images[index].alt}</span>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="absolute right-5 h-8 w-8 p-[4px] hover:bg-gray-900/10 rounded-lg cursor-pointer" onClick={() => setOpen(false)}>
                            <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd"></path>
                        </svg>
                    </div>
                </div>
                <img src={images[index].src} alt={images[index].alt} className='' />
                <button onClick={handlePrev} aria-label='Imagen Previa' className="!absolute top-2/4 left-4 -translate-y-2/4 rounded-full select-none transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-12 max-w-[48px] h-12 max-h-[48px] text-white hover:bg-white/10 active:bg-white/30 grid place-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeLinecap="3" className="-ml-1 h-7 w-7">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path>
                    </svg>
                </button>
                <button onClick={handleNext} aria-label='Imagen Siguiente' className="!absolute top-2/4 right-4 -translate-y-2/4 rounded-full select-none transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-12 max-w-[48px] h-12 max-h-[48px] text-white hover:bg-white/10 active:bg-white/30 grid place-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeLinecap="3" className="ml-1 h-7 w-7">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path>
                    </svg>
                </button>
            </dialog>
            */}
            {open ?
                <div style={{position: "fixed", overflow: "auto", inset: "0px"}} >
                    <span tabIndex="0" aria-hidden="true" data-floating-ui-focus-guard="" data-aria-hidden="true" style={spanStyle}></span>
                    <div className="grid place-items-center fixed w-screen h-screen bg-black bg-opacity-60 backdrop-blur-sm" style={{opacity: "1"}}>
                        <div tabIndex="-1" className="relative bg-box dark:bg-body_dark m-4 rounded-lg shadow-2xl text-blue-gray-500 antialiased font-sans text-base font-light leading-relaxed" aria-labelledby=":R54b9km:-label" aria-describedby=":R54b9km:-description" id=":R54b9kmH1:" role="dialog" style={{opacity: "1", transform: "none"}}>
                            <div className='w-full bg-[#ffffff8a] dark:bg-[#0000008a] rounded-t-lg backdrop-blur-lg'>
                                <div className="relative flex items-center justify-between pl-5 h-[50px]">
                                    <div className='flex gap-5'>
                                        <img src={IconoZabala} alt='Logo Luarca Zabala Hotel' className='h-[50px] py-2' />   
                                        <span className='flex items-center text-secondary'>{images[index].alt}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="absolute right-5 h-8 w-8 p-[4px] hover:bg-gray-900/10 rounded-lg cursor-pointer" onClick={() => setOpen(false)}>
                                        <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd"></path>
                                    </svg>
                                </div>
                            </div>
                            <img src={images[index].src} alt={images[index].alt} className='rounded-b-lg' />
                            <button onClick={handlePrev} aria-label='Imagen Previa' className="!absolute top-2/4 left-4 -translate-y-2/4 rounded-full select-none transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-12 max-w-[48px] h-12 max-h-[48px] text-white hover:bg-white/10 active:bg-white/30 grid place-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeLinecap="3" className="-ml-1 h-7 w-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path>
                                </svg>
                            </button>
                            <button onClick={handleNext} aria-label='Imagen Siguiente' className="!absolute top-2/4 right-4 -translate-y-2/4 rounded-full select-none transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-12 max-w-[48px] h-12 max-h-[48px] text-white hover:bg-white/10 active:bg-white/30 grid place-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeLinecap="3" className="ml-1 h-7 w-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <span tabIndex="0" aria-hidden="true" data-floating-ui-focus-guard="" data-aria-hidden="true" style={spanStyle}></span>
                </div> 
                : ""
            }
        </>
    )
}
