import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import { MdArrowOutward } from "react-icons/md";
import { registrar_evento, save_page_view } from '../contexts/analytics';

const locations = [
  {
    "nombre": "what_is_near",
    "icono": <Person className={"size-[20px]"} />,
    "componentes": [
      {
        "nombre": "Supermercado",
        "distancia": "300 Metros",
        "href": "https://www.google.es/maps/dir/HOTEL+ZABALA,+Ctra.+Nacional+634,+Luarca,+Almu%C3%B1a,+Asturias,+Vald%C3%A9s/Supermercados+Alimerka,+Luarca,+Vald%C3%A9s/@43.5337912,-6.5243861,17.42z/data=!4m14!4m13!1m5!1m1!1s0xd3156d1eb66f283:0xa72e7b41d6d3c5!2m2!1d-6.5260383!2d43.5339828!1m5!1m1!1s0xd3156ce91bd3ab1:0xdbffb7ed870d8b2f!2m2!1d-6.521828!2d43.53427!3e0?entry=ttu"
      },
      {
        "nombre": "Terraza Bar",
        "distancia": "20 Metros",
        "href": "https://www.google.es/maps/dir/HOTEL+ZABALA,+Ctra.+Nacional+634,+Luarca,+Almu%C3%B1a,+Asturias,+Vald%C3%A9s/%5BALMU%C3%91A%5D+Casa+Zabala+%5BCTA+04547%5D,+Almu%C3%B1a,+33700+Vald%C3%A9s,+Asturias/@43.5340229,-6.5281828,17z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0xd3156d1eb66f283:0xa72e7b41d6d3c5!2m2!1d-6.5260383!2d43.5339828!1m5!1m1!1s0xd3156d1e10ba0bb:0x12779190efb5b875!2m2!1d-6.5251872!2d43.5341213!3e0?entry=ttu"
      },
      {
        "nombre": "Parada de Autobus",
        "distancia": "20 Metros",
        "href": "https://www.google.es/maps/dir/HOTEL+ZABALA,+Ctra.+Nacional+634,+Luarca,+Almu%C3%B1a,+Asturias,+Vald%C3%A9s/%5BALMU%C3%91A%5D+Casa+Zabala+%5BCTA+04547%5D,+Almu%C3%B1a,+33700+Vald%C3%A9s,+Asturias/@43.5340229,-6.5281828,17z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0xd3156d1eb66f283:0xa72e7b41d6d3c5!2m2!1d-6.5260383!2d43.5339828!1m5!1m1!1s0xd3156d1e10ba0bb:0x12779190efb5b875!2m2!1d-6.5251872!2d43.5341213!3e0?entry=ttu"
      }
    ]
  },
  {
    "nombre": "featured_tourist_attractions",
    "icono": <FeaturedTouristAttractions className={"size-[20px]"} />,
    "componentes": [
      {
        "nombre": "La Regalina",
        "distancia": "14,4 km",
        "href": "https://www.google.es/maps/dir/HOTEL+ZABALA,+Ctra.+Nacional+634,+Luarca,+Almu%C3%B1a,+Asturias,+Vald%C3%A9s/Ermita+de+La+Regalina,+Cadav%C3%A9u,+Vald%C3%A9s/@43.5338302,-6.4905189,13z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0xd3156d1eb66f283:0xa72e7b41d6d3c5!2m2!1d-6.5260383!2d43.5339828!1m5!1m1!1s0xd36ab7318136079:0xf978e165da1c6c5!2m2!1d-6.3726!2d43.5547933!3e0?entry=ttu"
      },
      {
        "nombre": "Parque de la vida",
        "distancia": "9,1 km",
        "href": "https://www.google.es/maps/dir/HOTEL+ZABALA,+Ctra.+Nacional+634,+Luarca,+Almu%C3%B1a,+Asturias,+Vald%C3%A9s/El+Parque+de+la+Vida,+La+Mata,+Vald%C3%A9s/@43.5338695,-6.5569541,14z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0xd3156d1eb66f283:0xa72e7b41d6d3c5!2m2!1d-6.5260383!2d43.5339828!1m5!1m1!1s0xd31574350f75fd9:0xdd5da26f6757bf03!2m2!1d-6.56702!2d43.5398445!3e0?entry=ttu"
      },
      {
        "nombre": "Jardines Fonte Baixa",
        "distancia": "3,00 Km",
        "href": "https://www.google.es/maps/dir/HOTEL+ZABALA,+Ctra.+Nacional+634,+Luarca,+Almu%C3%B1a,+Asturias,+Vald%C3%A9s/Bosque-Jard%C3%ADn+de+La+Fonte+Baxa,+El+Chano,+33700+Luarca,+Asturias/@43.5381179,-6.5370158,16z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0xd3156d1eb66f283:0xa72e7b41d6d3c5!2m2!1d-6.5260383!2d43.5339828!1m5!1m1!1s0xd31572187548f43:0xf6858293d205ac2a!2m2!1d-6.5375348!2d43.5439922!3e0?entry=ttu"
      },
      {
        "nombre": "Museo Del Calamar Gigante",
        "distancia": "2,50 Km",
        "href": "https://www.google.es/maps/dir/HOTEL+ZABALA,+Ctra.+Nacional+634,+Luarca,+Almu%C3%B1a,+Asturias,+Vald%C3%A9s/Museo+del+Calamar+Gigante,+C.+Nicanor+del+Campo,+35,+33700+Luarca,+Asturias/@43.5376453,-6.5376599,16z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0xd3156d1eb66f283:0xa72e7b41d6d3c5!2m2!1d-6.5260383!2d43.5339828!1m5!1m1!1s0xd3156e0697e45d9:0x7db298a467656458!2m2!1d-6.5388793!2d43.5409526!3e0?entry=ttu"
      },
      {
        "nombre": "Campo de golf",
        "distancia": "10,6 km",
        "href": "https://www.google.es/maps/dir/HOTEL+ZABALA,+Ctra.+Nacional+634,+Luarca,+Almu%C3%B1a,+Asturias,+Vald%C3%A9s/Cam.+del+Campo+de+Golf,+Canedo,+33792+Vald%C3%A9s,+Asturias/@43.5379112,-6.5608201,14z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0xd3156d1eb66f283:0xa72e7b41d6d3c5!2m2!1d-6.5260383!2d43.5339828!1m5!1m1!1s0xd315761f07cc3f5:0xd8b117e60fb4ea89!2m2!1d-6.5725191!2d43.5506688!3e0?entry=ttu"
      }
    ]
  },
  {
    "nombre": "beaches_in_the_area",
    "icono": <Beach className={"size-[20px]"} />,
    "componentes": [
      {
        "nombre": "Playa de Luarca",
        "distancia": "2,80 Km",
        "href": "https://www.google.es/maps/dir/HOTEL+ZABALA,+Ctra.+Nacional+634,+Luarca,+Almu%C3%B1a,+Asturias,+Vald%C3%A9s/Playa+de+Luarca/@43.5394735,-6.5426678,15z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0xd3156d1eb66f283:0xa72e7b41d6d3c5!2m2!1d-6.5260383!2d43.5339828!1m5!1m1!1s0xd31571f710e7643:0x5eee795cf5d69163!2m2!1d-6.5386042!2d43.5467042!3e0?entry=ttu"
      },
      {
        "nombre": "Playa de Las Catedrales",
        "distancia": "59,30 km",
        "href": "https://www.google.es/maps/dir/HOTEL+ZABALA,+Ctra.+Nacional+634,+Luarca,+Almu%C3%B1a,+Asturias,+Vald%C3%A9s/Playa+de+Las+Catedrales/@43.5394735,-6.5426678,15z/data=!4m14!4m13!1m5!1m1!1s0xd3156d1eb66f283:0xa72e7b41d6d3c5!2m2!1d-6.5260383!2d43.5339828!1m5!1m1!1s0xd3180bdae870a2d:0x1bedf2aa65a720b7!2m2!1d-7.1572259!2d43.5540577!3e0?entry=ttu"
      },
      {
        "nombre": "Playa del Silencio",
        "distancia": "24,70 km",
        "href": "https://www.google.es/maps/dir/HOTEL+ZABALA,+Ctra.+Nacional+634,+Luarca,+Almu%C3%B1a,+Asturias,+Vald%C3%A9s/Playa+del+Silencio+O+Gaviero,+Cudillero/@43.5392583,-6.9948229,11z/data=!4m14!4m13!1m5!1m1!1s0xd3156d1eb66f283:0xa72e7b41d6d3c5!2m2!1d-6.5260383!2d43.5339828!1m5!1m1!1s0xd36a972cc912dc1:0xfca8503de9c1ea67!2m2!1d-6.2968588!2d43.565781!3e0?entry=ttu"
      },
      {
        "nombre": "Playa de Cueva",
        "distancia": "6,0 km",
        "href": "https://www.google.es/maps/dir/HOTEL+ZABALA,+Ctra.+Nacional+634,+Luarca,+Almu%C3%B1a,+Asturias,+Vald%C3%A9s/Playa+de+Cueva,+Cueva,+Vald%C3%A9s/@43.5497551,-6.4869425,12z/data=!4m14!4m13!1m5!1m1!1s0xd3156d1eb66f283:0xa72e7b41d6d3c5!2m2!1d-6.5260383!2d43.5339828!1m5!1m1!1s0xd31546afb000001:0x585e680b529664cb!2m2!1d-6.473721!2d43.5504644!3e0?entry=ttu"
      },
      {
        "nombre": "Playa de Otur",
        "distancia": "12,2 km",
        "href": "https://www.google.es/maps/dir/HOTEL+ZABALA,+Ctra.+Nacional+634,+Luarca,+Almu%C3%B1a,+Asturias,+Vald%C3%A9s/Playa+de+Otur/@43.5421687,-6.5184106,14z/data=!4m14!4m13!1m5!1m1!1s0xd3156d1eb66f283:0xa72e7b41d6d3c5!2m2!1d-6.5260383!2d43.5339828!1m5!1m1!1s0xd3159c88b90d85d:0x75b6c7007c5277c!2m2!1d-6.59736!2d43.5531623!3e0?entry=ttu"
      }
    ]
  },
  {
    "nombre": "nearest_airports",
    "icono": <AirPorts className={"size-[20px]"} />,
    "componentes": [
      {
        "nombre": "Aeropuerto de Asturias",
        "distancia": "47,60 km",
        "href": "https://www.google.es/maps/dir/HOTEL+ZABALA,+Ctra.+Nacional+634,+Luarca,+Almu%C3%B1a,+Asturias,+Vald%C3%A9s/Aeropuerto+de+Asturias+(OVD),+AI-82,+Santiago+del+Monte,+Castrill%C3%B3n/@43.5436597,-6.4421321,11z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0xd3156d1eb66f283:0xa72e7b41d6d3c5!2m2!1d-6.5260383!2d43.5339828!1m5!1m1!1s0xd369f2c3c3b0a1b:0x7bd91d14f2f85da!2m2!1d-6.0334978!2d43.5623825!3e0?entry=ttu"
      }
    ]
  },
]

export default function Localizacion() {

  const { t } = useTranslation();

  const [localizaciones, setLocalizaciones] = React.useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    window.document.title = "Luarca Zabala Hotel - Localizacion"
    save_page_view("Localización");

    // Hacer fetch api
    
    fetch('https://luarcazabalahotel.com/api/alrededores')
      .then(response => response.json())
      .then(data => {
        //window.alert(JSON.stringify(data));
        /*
        {
    "what_is_near": [
        {
            "id": "1",
            "nombre": "sdkajdfs",
            "url": "https:\/\/pabloeguilaz.es",
            "grupo": "what_is_near"
        }
    ],
    "atracciones": [
        {
            "id": "2",
            "nombre": "asd",
            "url": "",
            "grupo": "atracciones"
        },
        {
            "id": "3",
            "nombre": "asda",
            "url": "",
            "grupo": "atracciones"
        }
    ]
}
        */

        let localizaciones = [];

        // añaadir al state el apartado del nombre y en componentes
        // añadir los componentes
        if (data['what_is_near']) {
          localizaciones.push({nombre: "what_is_near", icono: <Person className={"size-[20px]"} />, componentes: data['what_is_near']})
        }
        if (data['featured_tourist_attractions']) {
          localizaciones.push({nombre: "featured_tourist_attractions", icono: <FeaturedTouristAttractions className={"size-[20px]"} />, componentes: data['featured_tourist_attractions']})
        }
        if (data['beaches_in_the_area']) {
          localizaciones.push({nombre: "beaches_in_the_area", icono: <Beach className={"size-[20px]"} />, componentes: data['beaches_in_the_area']})
        }
        if (data['nearest_airports']) {
          localizaciones.push({nombre: "nearest_airports", icono: <AirPorts className={"size-[20px]"} />, componentes: data['nearest_airports']})
        }

        setLocalizaciones(localizaciones);


      })



  }, [])

  return (
    <>
      <h1 className='text-2xl dark:text-white'>{t("location")}</h1>
      <h2 className='text-xl dark:text-white'>{t("map")}</h2>
      <section className='w-full'>
        <iframe className='w-full h-[409px] lg:h-[600px] rounded-lg mt-4 mb-5 border-2 transition-all' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4842.280145004403!2d-6.5258084535697!3d43.53573692354257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3156d1eb66f283%3A0xa72e7b41d6d3c5!2sHOTEL%20ZABALA!5e0!3m2!1ses!2ses!4v1709980901959!5m2!1ses!2ses" />
      </section>
      <h2 className='text-xl '>{t("hotel_surroundings")}</h2>
      <section className='grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] lg:grid-cols-[repeat(auto-fill,minmax(500px,1fr))] mt-5 gap-5'>
        {
          localizaciones.map((location, index) => (
            <div key={index} className='w-full hover:shadow-md p-2 rounded-lg border border-box_border text-black bg-box shadow cursor-pointer transition-all'>
                <div className='flex items-center gap-2 p-[8px] pb-0'>
                    <p className='dark:fill-white'>{location.icono}</p>
                    <h3 className='text-sm font-semibold'>{t(location.nombre)}</h3>
                </div>
                <ul className='my-4'>
                  {
                    location.componentes.map((componente, index) => (
                      <a href={componente.href} target="_blank" className='group' >
                        <li key={index} className='text-sm flex justify-between hover:bg-body p-2 rounded-lg'>
                          <span>{componente.nombre}</span>
                          <p className='flex items-center gap-3'>
                            <span>{componente.distancia}</span>
                            <span className='block md:hidden group-hover:block'><MdArrowOutward /></span>
                          </p>
                        </li>
                      </a>
                    ))
                  }
                </ul>
            </div>
          ))
        }
      </section>
    </>
  )
}

function Person({className}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.974 3.554a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0zm1.5 0a3.375 3.375 0 1 0-6.75 0 3.375 3.375 0 0 0 6.75 0zm3.675 7.496a1.488 1.488 0 0 1-1.168-.56 8.45 8.45 0 0 0-6.634-3.19A6.756 6.756 0 0 0 3.6 14.05a2.25 2.25 0 1 0 4.5 0 2.255 2.255 0 0 1 1.689-2.174l-.189-.726h-.75v.5a15.568 15.568 0 0 1-2.622 8.66 2.252 2.252 0 1 0 3.748 2.496 20.15 20.15 0 0 0 2.336-4.768l-1.111.396a7.288 7.288 0 0 1 3.018 3.838c.343 1.165 1.595 1.846 2.787 1.494a2.25 2.25 0 0 0 1.47-2.944 11.771 11.771 0 0 0-5.785-6.712l.398.788c.175-1.074.263-2.16.263-3.248v-.03h-.75l-.356.66a3.98 3.98 0 0 1 1.223 1.022 5.971 5.971 0 0 0 4.688 2.252 2.25 2.25 0 0 0-.002-4.5v.75l.6-.45-.003-.004a.75.75 0 0 0-.602-.3zm.004 1.5l-.002-.75-.6.45.003.004a.75.75 0 0 0 .6.3.75.75 0 0 1 0 1.5 4.473 4.473 0 0 1-3.513-1.687 5.48 5.48 0 0 0-1.684-1.407.75.75 0 0 0-1.106.66v.03c0 1.007-.081 2.012-.243 3.006a.75.75 0 0 0 .398.788 10.288 10.288 0 0 1 5.081 5.952.75.75 0 0 1-1.439.425A8.804 8.804 0 0 0 12 17.166a.75.75 0 0 0-1.111.396 18.65 18.65 0 0 1-2.162 4.412.752.752 0 0 1-1.252-.832 17.066 17.066 0 0 0 2.874-9.493v-.499a.75.75 0 0 0-.939-.726A3.756 3.756 0 0 0 6.6 14.048a.75.75 0 1 1-1.5.002 5.256 5.256 0 0 1 5.25-5.25 6.95 6.95 0 0 1 5.459 2.624 2.989 2.989 0 0 0 2.345 1.126z"></path></svg>
  )
}

function FeaturedTouristAttractions({className}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.5 3a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM15 3a3 3 0 1 0-6 0 3 3 0 0 0 6 0zm6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm1.5 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0zM6 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm1.5 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0zM21 15a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm1.5 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0zm-9-3.75a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm1.5 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0zM6 15a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm1.5 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0zm10.066 1.277a7.5 7.5 0 0 1-3.077 2.05.75.75 0 0 0 .498 1.415 9 9 0 0 0 3.693-2.46.75.75 0 1 0-1.114-1.005zm1.798-6.466c.177.922.183 1.869.015 2.792a.75.75 0 1 0 1.476.268c.2-1.106.194-2.24-.019-3.344a.75.75 0 1 0-1.472.284zm-5.337-5.784a7.5 7.5 0 0 1 3.54 2.196.75.75 0 0 0 1.113-1.004 9.002 9.002 0 0 0-4.247-2.636.75.75 0 1 0-.406 1.444zM6.434 6.223a7.5 7.5 0 0 1 3.539-2.196.75.75 0 1 0-.406-1.444A9.001 9.001 0 0 0 5.32 5.219a.75.75 0 0 0 1.114 1.004zM4.636 12.69a7.602 7.602 0 0 1 0-2.878.75.75 0 1 0-1.472-.284 9.102 9.102 0 0 0 0 3.446.75.75 0 0 0 1.472-.284zm4.876 5.639a7.517 7.517 0 0 1-3.035-2.005.75.75 0 0 0-1.106 1.014 9.017 9.017 0 0 0 3.641 2.405.75.75 0 1 0 .5-1.414zM7.31 21.872A1.5 1.5 0 0 0 8.672 24h6.656a1.5 1.5 0 0 0 1.362-2.128l-3.314-8.217c-.361-.785-1.252-1.114-2.005-.767a1.5 1.5 0 0 0-.733.734l-3.343 8.283zm1.377.595l3.328-8.25-.015.033 3.313 8.217.015.033H8.672z"></path></svg>
  )
}

function Beach({className}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M.153 22.237l.85 1.117c.634.76 1.724.856 2.456.244.078-.066.15-.138.216-.217l.944-1.132a.228.228 0 0 1 .349.001l.944 1.13a1.728 1.728 0 0 0 2.651.001l.944-1.132a.228.228 0 0 1 .349.001l.95 1.132a1.728 1.728 0 0 0 2.65 0l.942-1.133a.228.228 0 0 1 .349.001l.942 1.13a1.728 1.728 0 0 0 2.651.001l.944-1.132a.228.228 0 0 1 .349.001l.94 1.13a1.728 1.728 0 0 0 2.652.001l.585-.7a.75.75 0 1 0-1.15-.962l-.585.7a.228.228 0 0 1-.35 0l-.94-1.13a1.728 1.728 0 0 0-2.652-.001l-.944 1.132a.228.228 0 0 1-.349-.001l-.942-1.13a1.728 1.728 0 0 0-2.651-.001l-.943 1.132a.228.228 0 0 1-.348-.001l-.95-1.132a1.726 1.726 0 0 0-2.65 0l-.944 1.133a.228.228 0 0 1-.349-.001l-.944-1.13a1.728 1.728 0 0 0-2.65 0l-.945 1.13a.228.228 0 0 1-.349-.001l-.828-1.09a.75.75 0 1 0-1.194.91zm11.335-2.68A7.161 7.161 0 0 1 15.77 18h7.481a.75.75 0 0 0 0-1.5h-7.5a8.673 8.673 0 0 0-5.196 1.884.75.75 0 1 0 .934 1.174zM22.285 7.969a1.729 1.729 0 0 0 .781-2.711C19.43.713 12.8-.022 8.256 3.614a10.536 10.536 0 0 0-3.952 8.171A1.73 1.73 0 0 0 6.6 13.427l15.684-5.459zm-.494-1.416L6.107 12.01a.229.229 0 0 1-.304-.218 9.036 9.036 0 0 1 16.09-5.599.228.228 0 0 1-.102.359zm-9.459-4.2L11.69.504a.75.75 0 1 0-1.416.492l.643 1.848a.75.75 0 1 0 1.416-.492zm1.156 7.883l2.527 7.262a.75.75 0 1 0 1.416-.494l-2.527-7.26a.75.75 0 1 0-1.416.492z"></path></svg>
  )
}

function Restaurants({className}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.999.75v22.5a.75.75 0 0 0 1.5 0V.75a.75.75 0 0 0-1.5 0zm3 0V7.5a2.259 2.259 0 0 1-2.252 2.25 2.258 2.258 0 0 1-2.248-2.252V.75a.75.75 0 0 0-1.5 0V7.5a3.76 3.76 0 0 0 3.748 3.75 3.76 3.76 0 0 0 3.752-3.748V.75a.75.75 0 0 0-1.5 0zm6.75 15.75h3c1.183.046 2.203-.9 2.25-2.111a2.22 2.22 0 0 0 0-.168c-.25-6.672-.828-9.78-3.231-13.533a1.508 1.508 0 0 0-2.77.81V23.25a.75.75 0 0 0 1.5 0V1.503c0 .003.001 0 .003 0a.006.006 0 0 1 .008.002c2.21 3.45 2.75 6.354 2.99 12.773v.053a.696.696 0 0 1-.721.67L15.749 15a.75.75 0 0 0 0 1.5z"></path></svg>
  )
}

function AirPorts({className}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.33 3.57L5.7 9.955l.79.07-1.96-1.478a.75.75 0 0 0-.753-.087l-2.1.925C.73 9.856.359 10.967.817 11.88c.11.22.263.417.45.577l3.997 3.402a2.94 2.94 0 0 0 3.22.4l3.62-1.8-1.084-.671v5.587a1.833 1.833 0 0 0 2.654 1.657l1.88-.932a1.85 1.85 0 0 0 .975-1.226l1.87-7.839-.396.498 3.441-1.707a3.494 3.494 0 1 0-3.11-6.259zm.672 1.342a1.994 1.994 0 0 1 1.775 3.571l-3.44 1.707a.75.75 0 0 0-.396.498l-1.87 7.838a.35.35 0 0 1-.185.232l-1.88.932a.335.335 0 0 1-.486-.304V13.79a.75.75 0 0 0-1.084-.672l-3.62 1.8a1.44 1.44 0 0 1-1.578-.197l-3.997-3.402a.35.35 0 0 1 .073-.577l2.067-.91-.754-.087 1.96 1.478a.75.75 0 0 0 .79.07l12.63-6.383zm-3.272.319l-4.46-2.26a1.852 1.852 0 0 0-1.656-.001l-1.846.912a1.85 1.85 0 0 0-.295 3.128l2.573 1.955a.75.75 0 1 0 .908-1.194L8.38 5.816a.35.35 0 0 1 .055-.591l1.845-.912a.351.351 0 0 1 .315 0l4.456 2.256a.75.75 0 0 0 .678-1.338z"></path></svg>
  )
}