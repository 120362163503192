import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStateContext } from '../contexts/ContextProvider';
import { FaWhatsapp } from "react-icons/fa";
import { toast } from 'sonner';
import { save_page_view } from '../contexts/analytics';

export default function Contacto() {

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        window.document.title = "Luarca Zabala Hotel - Contacto"
        save_page_view("Contacto");
    }, [])

    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [consulta, setConsulta] = useState('');

    const nombreHandler = (e) => {
        setNombre(e.target.value);
    }

    const emailHandler = (e) => {
        setEmail(e.target.value);
    }

    const consultaHandler = (e) => {
        setConsulta(e.target.value);
    }

    const enviarConsulta = () => {

        if (nombre === '' || email === '' || consulta === '') {
            toast.error('Por favor, rellene todos los campos');
            return;
        }

        // Aun por probar
        /*
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            toast.error('Email no válido');
            return;
        }
        */

        const raw = JSON.stringify({
            nombre: nombre,
            email: email,
            consulta: consulta,
            establecimiento: "Luarca Zabala Hotel"
        });

        const requestOptions = {
            method: "POST", 
            body: raw,
        };

        toast.promise(
            fetch("https://api.luarcaexperience.com/sendMail", requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((result) => {

                    console.log(result);

                    if (result.code === 200) {
                        console.log("Result: ", result);
                        //Vaciar campos
                        //setNombre('');
                        //setEmail('');
                        //setConsulta('');
                        return result.message;
                    } else {
                        console.error("Error: ", result.error);
                        throw new Error(result.error);
                    }
                })
                .catch((error) => {
                    console.error("Error: ", error);
                    throw new Error(error);
                }),
            {
                loading: t("loading") + '...',
                success: (message) => `${message}`,
                error: (error) => error.error || 'Error desconocido',
            }
        );
          
    }

    return (
        <>
            <section className='relative h-[300px]'>
                <h1 className={"absolute top-[50%] left-[50%] transform translate-x-[-50%] -translate-y-[50%] w-full text-white text-center"}>{t("contact")}</h1>
                <img src={process.env.REACT_APP_STATIC_URL + "/8FD50BAC-FB25-4C02-B4F1-66254FE834BB.jpeg"} alt="Zabala Hotel" className='w-full h-full object-cover rounded-lg'/>
            </section>
            <section className='my-5'>
                <form>
                    <div>
                        <label htmlFor='contacto_nombre' className="block text-sm font-semibold leading-6 text-gray-900">{t("name")}</label>
                        <div className="mt-2.5">
                            <input  
                                type="text" 
                                id="contacto_nombre" 
                                className="outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 peer pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-box_border placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-box_border sm:text-sm sm:leading-6 bg-box"
                                placeholder={t("name")}
                                onChange={nombreHandler}
                            />
                        </div>
                    </div>
                    <div className='mt-5'>
                        <label htmlFor='contacto_email' className="block text-sm font-semibold leading-6 text-gray-900">{t("email")}</label>
                        <div className="mt-2.5">
                            <input  
                                type="email" 
                                id="contacto_email" 
                                className="outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 peer pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-box_border placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-box_border sm:text-sm sm:leading-6 bg-box"
                                placeholder={t("email")}
                                onChange={emailHandler}
                            />
                        </div>
                    </div>
                    <div className='mt-5'>
                        <label htmlFor='contacto_consultation' className="block text-sm font-semibold leading-6 text-gray-900">{t("consultation")}</label>
                        <div className="mt-2.5">
                            <textarea 
                                id="contacto_consultation" 
                                rows="4" 
                                className="outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 peer pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-box_border placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-box_border sm:text-sm sm:leading-6 bg-box"
                                onChange={consultaHandler}
                            ></textarea>
                        </div>
                    </div>
                    <div className='flex gap-5'>
                        <button 
                            type="button" 
                            onClick={enviarConsulta}
                            className='mt-5 bg-primary text-white px-5 py-2 rounded-md hover:scale-105 transition-all cursor-pointer'
                        >{t("send")}</button>
                        <a href={'https://api.whatsapp.com/send?phone=' + process.env.REACT_APP_PHONE_NUMBER + '&text=' + consulta} target='_blank' className='flex items-center gap-2 bg-[#24d366] text-white rounded-lg p-2 mt-5 hover:scale-105 transition-all cursor-pointer'>
                            <FaWhatsapp className='' />
                            <span>Whatsapp</span>
                        </a>
                    </div>
                </form>
            </section>
        </>
    )
}
