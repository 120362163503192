import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';

import { registrar_evento } from '../../contexts/analytics';
import DatePicker from '../DatePicker';

import { toast } from 'sonner';
export default function Buscador({className}) {

    const { t } = useTranslation();

    const [entrada, setEntrada] = useState(null); 
    const [salida, setSalida] = useState(null);


    function buscarDisponobilidad() {

        registrar_evento("Buscar Disponobilidad")

        const fechaActual = new Date();

        fechaActual.setHours(0, 0, 0, 0);

        if (entrada == null && salida == null) {
            /*
            setShowAlert(true);
            setAlertTitle('Error');
            setAlertMessage('Las fechas no pueden estar vacías.');
            */
            toast.error('Las fechas no pueden estar vacías.');
        } 

        // Verificar si la fecha de entrada es mayor o igual que la fecha actual
        if (entrada < fechaActual) {
            /*
            setShowAlert(true);
            setAlertTitle('Error');
            setAlertMessage('La fecha de entrada debe ser mayor o igual que la fecha actual.');
            */
            toast.error('La fecha de entrada debe ser mayor o igual que la fecha actual.');
        } else if (salida <= entrada) {
            /*
            setShowAlert(true);
            setAlertTitle('Error');
            setAlertMessage('La fecha de salida debe ser posterior a la fecha de entrada.');
            */
            toast.error('La fecha de salida debe ser posterior a la fecha de entrada.');
        } else  {
            sessionStorage.setItem('fechaini', stringDate(entrada));
            sessionStorage.setItem('fechafin', stringDate(salida));
            sessionStorage.setItem('diasreserva', (salida - entrada) / (1000 * 60 * 60 * 24))
            window.location.href = '/bookings?fechaini=' + stringDate(entrada) + '&fechafin=' + stringDate(salida);
        }
    }

    function stringDate(date) {
        return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, "0") + '-' + (date.getDate()).toString().padStart(2, "0")
    }

    return (
        <div className={className}>
            <div className="formulario1 pb-[20px] w-full flex justify-center pt-5 lg:pt-0">
                <div className='lg:w-[450px] w-full px-5 lg:px-0'>
                    <h3 className='text-xl pb-[15px]'>{t("select_dates")}</h3>
                    <div className='flex justify-between lg:flex-row flex-col gap-2'>
                        <div className="w-full">
                            <label htmlFor="fechainiB" className=''>{t("entrance")}</label><br />
                            <input type="hidden" name="fechainiB" id="fechainiB" required="required" value={entrada} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex items-center w-full p-2.5'/>
                            <DatePicker 
                                text={t("entrance")} 
                                onSelectDate={setEntrada} 
                                dateBeforeDisabled={true}    
                            />
                        </div>
                        <div className="w-full">
                            <label htmlFor="fechafinB" >{t("exit")}</label><br />
                            <input type="hidden" name="fechafinB" id="fechafinB" required="required" value={salida} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex items-center w-full p-2.5' />
                            <DatePicker 
                                text={t("exit")} 
                                onSelectDate={setSalida} 
                                dateBeforeDisabled={true}
                            />
                        </div>
                        <div>
                            <input type="button" name='Buscar Disponibilidad' value={t("search")} onClick={() => buscarDisponobilidad()} className="text-white px-[15px] py-[10px] hover:scale-[1.1] rounded-lg mt-[23px] transition btn_siguiente cursor-pointer bg-nav w-full" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
