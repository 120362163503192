import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import { AñadirCalendario, Reservas } from '../components';

import { save_page_view } from '../contexts/analytics';

export default function Bookings() {

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        window.document.title = "Luarca Zabala Hotel - Reservas"
        save_page_view("Reservas");
    }, [])

    return (
        <>
            <section className='relative h-[300px] bg-no-repeat bg-cover bg-center rounded-lg' style={{"backgroundImage": "url(" + process.env.REACT_APP_STATIC_URL + "/P4040331.jpeg" + ")"}}>
                {/*<img src={process.env.REACT_APP_STATIC_URL + "/P4040331.jpeg"} alt="Zabala Hotel" className='w-full h-full object-cover rounded-lg'/>*/}
                <h1 className={"absolute top-[50%] left-[50%] transform translate-x-[-50%] -translate-y-[50%] w-full text-white text-center"}>{t("nav_bookings")}</h1>
            </section>
            <Reservas />
            {/*
            <AñadirCalendario 
                text={t("nav_bookings")}
                dates="2022-01-01"
                details={"asdkjaskdjasd"}
                className={"text-2xl text-center font-bold hover:underline"}
            />
            */} 
            <section className='text-sm text-slate-500'>
                <p>Check-in 15:00h. - Check-out 12:00h.</p>
                <h3 className='my-2 text-slate-700'>{t("cancellation_policy")}</h3>
                <ul className='list-disc ml-3'>
                    <li>{t("cancellation_policy_text_1")}</li>
                    <li>{t("cancellation_policy_text_2")}</li>
                    <li>{t("cancellation_policy_text_3")}</li>
                </ul>
                <h3 className='my-2 text-slate-700'>{t("check_reservation")}</h3>
                <p>{t("check_reservation_text")} <Link to={"/consulta"} className='hover:underline'>{t("here")}</Link>.</p>
                <h3 className='my-2 text-slate-700'>{t("check_in_online")}</h3>
                <p>{t("check_in_online_text ")} <Link to={"/consulta"} className='hover:underline'>{t("here")}</Link>.</p>
            </section>
        </>
    )
}
