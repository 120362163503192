import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';
import { Link } from 'react-router-dom';

import { IconoZabala, IconoZabalaWhite } from '../resources/'


const navItems = [
    {
        name: 'home',
        path: ''
    },
    /*
    {
        name: 'bedrooms',
        path: '/bedrooms'
    },
    */
    {
        name: 'bookings',
        path: '/bookings'
    },
    {
        name: 'around',
        path: '/location'
    },
    {
        name: 'gallery',
        path: '/gallery'
    },
    {
        name: 'contact',
        path: '/contact'
    }
]

export default function Header() {

    const { t } = useTranslation();

    const [verMenuMovil, setVerMenuMovil] = useState(false) 

    return (
        <nav className="bg-primary sticky top-0 z-30 shadow">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                    <div className="flex w-full">
                        <div className="hidden lg:flex flex-shrink-0 items-center">
                            <Link to="/" className='h-full'>
                                <img src={IconoZabalaWhite} alt='Logo Luarca Zabala Hotel' className='h-full py-2' />
                            </Link>
                            <p className="pl-3 hidden lg:block"></p>
                        </div>
                        <div className="hidden w-full lg:-my-px lg:ml-6 lg:flex lg:space-x-8">
                            {
                                navItems.map(e => {
                                    return (
                                        <Link to={e.path} key={e.name} className='inline-flex items-center whitespace-nowrap pt-1 px-1 text-white text-sm hover:underline'>{t('nav_'+e.name)}</Link>
                                    )
                                })
                            }
                            <div className="w-full flex justify-end items-center">
                                <LanguageSelector className={"inline-flex items-center pt-1 px-1 text-white text-sm"} />
                            </div>
                        </div>
                        {/* Mobile Menu Header */}
                        <div className="flex justify-end s-my-px ml-6 lg:hidden space-x-8 w-full">
                            {
                            verMenuMovil ?
                                <p onClick={() => setVerMenuMovil(verMenuMovil == true ? false : true)} className="cursor-po text-white border-transparent hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" className='w-[20px] h-[20px]' xmlns="http://www.w3.org/2000/svg"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></p>
                            :
                                <p onClick={() => setVerMenuMovil(verMenuMovil == true ? false : true)} className="cursor-po text-white border-transparent hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="block h-6 w-6"><path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path></svg></p>
                            }
                            <div className="flex justify-center content-center items-center w-full">
                                <img src={IconoZabalaWhite} alt='Logo Luarca Zabala Hotel' className='h-full py-2' />
                            </div>
                            <LanguageSelector className={"inline-flex items-center pt-1 px-1 text-white text-sm"} />
                        </div>

                    </div>
                </div>
            </div>
            {/* Mobile Menu*/}
            <div className={verMenuMovil == true ? "lg:hidden" : "hidden"} id="headlessui-disclosure-panel-:R4ba:" data-headlessui-state="open">
            <div className="space-y-1 pt-2 pb-3">
                {
                    navItems.map(e => {
                        return (
                            <Link to={e.path} key={e.name} onClick={() => {setVerMenuMovil(verMenuMovil == true ? false : true)}} className={"border-transparent text-white hover:underline hover:border-white block pl-3 pr-4 py-2 border-l-4 text-base font-medium"}>{t('nav_'+e.name)}</Link>
                        )
                    })
                }
            </div>
            </div>
        </nav>
    )
}
