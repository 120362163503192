import React, { useEffect } from 'react'
import { save_page_view } from '../contexts/analytics';

export default function PrivacyPolicy() {

  const nombre_fiscaL = "Abelardo Pérez Monteavaro"
  const email = "info@luarcazabalahotel.com"

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    window.document.title = "Luarca Zabala Hotel - Politica de Privacidad"
    save_page_view("Política de Privacidad")
  }, [])

  return (
    <>
      <h3 className='mb-4 text-xl'>1. Política de privacidad sobre datos de carácter personal</h3>
      <p>
        En cumplimiento de la normativa legal vigente en materia de Protección de Datos 
        de Carácter Personal, {nombre_fiscaL} informa a los usuarios de esta página 
        web que los datos de carácter personal que pudiesen ser recogidos a través de 
        la misma mediante formularios, correos electrónicos (por ejemplo, solicitando 
        información), suscripciones a la newsletter de la entidad o por cualquier otro 
        medio (por ejemplo, y sin carácter limitativo, enviando una solicitud de empleo 
        o dirigiéndose al servicio técnico) y que tienen la consideración de datos de 
        carácter personal, serán incorporados a ficheros y tratados automatizadamente 
        por parte de {nombre_fiscaL}
      </p>
      <br />
      <h3 className='my-4 text-xl'>2. Qué información es recabada</h3>
      <p>
        Aunque la navegación por este sitio web no requiere la cesión de información de 
        carácter personal, en ocasiones {nombre_fiscaL} necesita solicitar ciertos 
        datos personales del usuario. En estos casos, en los formularios de recogida de 
        datos, los campos obligatorios para poder tramitar las peticiones o consultas 
        será indispensable cumplimentarlos para poder continuar con el envío del formulario. 
        Con respecto al resto de campos, el usuario puede decidir si quiere o no ceder 
        voluntariamente dichos datos a {nombre_fiscaL}, ya sea para mejorar la calidad 
        del servicio, recibir (o no) información de carácter comercial, suscribirse a 
        boletines informativos y/o newsletters, contratar un producto, solicitar información, 
        etc.
      </p>
      <br />
      <h3 className='my-4 text-xl'>3. Responsabilidad sobre la veracidad de los datos personales</h3> 
      <p>
        Los usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, 
        vigencia y autenticidad de los datos personales proporcionados y se comprometen 
        a mantenerlos debidamente actualizados.
      </p>
      <br />
      <h3 className='my-4 text-xl'>4. Datos personales de menores</h3>
      <p>
        Los usuarios garantizan ser mayores de 18 años. {nombre_fiscaL} no recoge 
        intencionadamente información sobre menores de edad, a no ser que cuente con el 
        consentimiento expreso de sus progenitores o responsables legales. Sin embargo, 
        {nombre_fiscaL} carece de las herramientas necesarias para verificar la 
        edad de los usuarios, por lo que no puede ser considerado responsable si entre 
        los datos de carácter personal almacenados figuran los de menores. Si tiene constancia 
        de que {nombre_fiscaL} cuenta con datos personales de menores de edad, por 
        favor, póngase en contacto con nosotros a través de la dirección {email} 
        y se procederá a su eliminación.
      </p>
      <br />
      <h3 className='my-4 text-xl'>5. Uso de datos personales</h3>
      <p>
        La finalidad del tratamiento es la de gestionar la petición realizada a través 
        de la web (ya sea mediante un formulario de contacto o cualquier otro tipo de 
        impreso online), del correo electrónico o de otros medios. Tal petición podrá 
        ser, sin carácter limitativo, una instancia de información comercial, una solicitud 
        de empleo o incluso el alta en boletines informativos de la entidad, así como 
        cualquier otra interacción entre el usuario de la web y {nombre_fiscaL} 
        que tienda a satisfacer una necesidad de éste. Todos los datos solicitados son 
        indispensables para poder atender la solicitud y no serán comunicados a terceros, 
        al margen de lo legalmente establecido.
      </p>
      <br />
      <p>
        En caso de que un usuario facilitara datos de carácter personal no siendo titular 
        de los mismos, este deberá, con carácter previo a su inclusión, informar a dichas 
        personas de los extremos contenidos en los párrafos anteriores y obtener su consentimiento 
        siempre que fuera preciso. {nombre_fiscaL} quedará exento de cualquier responsabilidad 
        si el usuario incumpliera estos requisitos.
      </p>
      <br />
      <h3 className='my-4 text-xl'>6. Derechos de acceso, rectificación, cancelación y oposición</h3>
      <p>
        El usuario podrá ejercer sus derechos de acceso, rectificación, cancelación y 
        oposición ante {nombre_fiscaL} solicitándolo por e-mail a {email}.
      </p>  
      <h3 className='my-4 text-xl'>7. Medidas de seguridad</h3>
      <p>
        El responsable del fichero ha adoptado las medidas técnicas y organizativas acordes 
        a los niveles de seguridad legalmente requeridos. Igualmente, ha instalado todos 
        los medios y medidas técnicas a su disposición según el estado de la tecnología 
        para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los 
        datos personales facilitados. Entre dichas medidas destacan el empleo de conexiones 
        seguras, el cifrado de la información y el uso de certificados de autenticación. 
        En todo caso, el usuario es consciente de que pueden existir riesgos asociados 
        a la navegación web, a la transmisión por Internet, al uso de redes wifi o al 
        propio sistema informático.
      </p>
      <br />
      <p>
        El usuario cuenta con la confidencialidad y el deber de secreto de los empleados 
        de {nombre_fiscaL} y de todos aquellos que traten los datos en nombre y 
        por cuenta del mismo, estando regulado mediante cláusulas contractuales. 
      </p>
      <h3 className='my-4 text-xl'>8. Dudas sobre tratamiento de datos y privacidad</h3>
      <p>
        Si tiene cualquier duda sobre la política de tratamiento de datos personales y 
        no personales o sobre la política de privacidad de {nombre_fiscaL}, puede 
        contactarnos en {email}.
      </p>
      <br />
      <p>{nombre_fiscaL} se reserva el derecho a actualizar su Política de Privacidad en cualquier momento.</p>
    </>
  )
}
