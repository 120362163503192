import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { registrar_evento } from '../contexts/analytics';

export default function GoogleTranslate({textToTranslate}) {
    const [translation, setTranslation] = useState('');

    const { i18n } = useTranslation();

    const handleTranslate = async () => {

      try {
        const response = await fetch(`https://translate.googleapis.com/translate_a/single?client=gtx&sl=auto&tl=${i18n.language}&dt=t&q=${textToTranslate}`);
        const data = await response.json();
  
        if (data && data[0] && data[0][0] && data[0][0][0]) {
          const translatedText = data[0][0][0];
          setTranslation(translatedText);
        } else {
          console.error('Error al traducir el texto');
        }
      } catch (error) {
        console.error('Error al realizar la traducción:', error);
      }
    };
  
    useEffect(() => {
      setTranslation(textToTranslate)
      handleTranslate()
    }, [i18n.language])

    return (
      translation
    );
}
