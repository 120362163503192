import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { BigGallery } from '../components';

import { save_page_view } from '../contexts/analytics';

const h2Style = {
    "fontSize": "32px", 
    "fontWeight": "400",
    "lineHeightt": "41px",
    "letterSpacing": "0em",
    "textAlign": "left"
}

const receptionImages = [
    {
        src: process.env.REACT_APP_STATIC_URL + "/IMG_5482.jpeg",
        alt: "Recepcion"
    },
    {
        src: process.env.REACT_APP_STATIC_URL + "/IMG_5483.jpeg",
        alt: "Recepcion"
    },
    {
        src: process.env.REACT_APP_STATIC_URL + "/IMG_5481.jpeg",
        alt: "Recepcion"
    },
    {
        src: process.env.REACT_APP_STATIC_URL + "/IMG_5484.jpeg",
        alt: "Recepcion"
    }
]

const habitacionesDobles = [
    {
        "src": process.env.REACT_APP_STATIC_URL + "/IMG_5456.jpeg",
        "alt": "Habitación Doble"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/IMG_5457.jpeg",
        "alt": "Habitación Doble"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/IMG_5458.jpeg",
        "alt": "Habitación Doble"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/IMG_5469.jpeg",
        "alt": "Habitación Doble"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/IMG_5470.jpeg",
        "alt": "Habitación Doble"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/IMG_5455.jpeg",
        "alt": "Habitación Doble"
    }
]

const habitacionDeMatrimonio = [
    {
        "src": process.env.REACT_APP_STATIC_URL + "/IMG_5451.jpeg",
        "alt": "Habitación De Matrimonio"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/IMG_5452.jpeg",
        "alt": "Habitación De Matrimonio"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/IMG_5454.jpeg",
        "alt": "Habitación De Matrimonio"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/IMG_5465.jpeg",
        "alt": "Habitación De Matrimonio"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/IMG_5467.jpeg",
        "alt": "Habitación De Matrimonio"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/IMG_5463.jpeg",
        "alt": "Habitación De Matrimonio"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/IMG_5466.jpeg",
        "alt": "Habitación De Matrimonio"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/IMG_5464.jpeg",
        "alt": "Habitación De Matrimonio"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/IMG_5468.jpeg",
        "alt": "Habitación De Matrimonio"
    }
]

const alrededores = [
    {
        "src": process.env.REACT_APP_STATIC_URL + "/IMG_5478.jpeg",
        "alt": "Alrededores"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/P4040122.jpeg",
        "alt": "Alrededores"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/P4040123.jpeg",
        "alt": "Alrededores"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/P4040333.jpeg",
        "alt": "Alrededores"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/P4040323.jpeg",
        "alt": "Alrededores"
    },
    {
        "src": process.env.REACT_APP_STATIC_URL + "/P4040324.jpeg",
        "alt": "Alrededores"
    },
]

export default function Galeria() {

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        window.document.title = "Luarca Zabala Hotel - Galeria"
        save_page_view("Galería"); 
      }, [])
    
    return (
        <div>  
            {/*
            <section>
                <h2 style={h2Style} className='text-primary my-5'>{t("reception")}</h2>
                <Gallery imgs={receptionImages} type={"flex"} />
            </section>
            <section>
                <h2 style={h2Style} className='text-primary my-5'>{t("double_room")}</h2>
                <Gallery imgs={habitacionDeMatrimonio} type={"flex"} />
            </section>
            <section>
                <h2 style={h2Style} className='text-primary my-5'>{t("Matrimonial")}</h2>
                <Gallery imgs={habitacionesDobles} type={"flex"} />
            </section>
            <section>
                <h2 style={h2Style} className='text-primary my-5'>{t("nav_around")}</h2>
                <Gallery imgs={alrededores} type={"flex"} />
            </section>
            */}
            <section>
                <h2 style={h2Style} className='text-primary my-5'>{t("reception")}</h2>
                <BigGallery images={receptionImages} />
            </section>
            <section>
                <div className='flex  items-center justify-between'>
                    <h2 style={h2Style} className='text-primary my-5'>{t("Matrimonial")}</h2>
                    <Link 
                        to={`/bookings`} 
                        className='text-white bg-primary py-2 px-3 hover:scale-[1.1] rounded-lg transition-all cursor-pointer flex justify-center items-center'
                    >{t("booking")}</Link>
                </div>
                <BigGallery images={habitacionDeMatrimonio} />
            </section>
            <section>
                <div className='flex  items-center justify-between'>
                    <h2 style={h2Style} className='text-primary my-5'>{t("double_room")}</h2>
                    <Link 
                        to={`/bookings`} 
                        className='text-white bg-primary py-2 px-3 hover:scale-[1.1] rounded-lg transition-all cursor-pointer flex justify-center items-center'
                    >{t("booking")}</Link>
                </div>
                <BigGallery images={habitacionesDobles} />
            </section>
            <section>
                <h2 style={h2Style} className='text-primary my-5'>{t("nav_around")}</h2>
                <BigGallery images={alrededores} />
            </section>
        </div>
    )
}
