import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { GoogleTranslate } from '../components';
import { save_page_view } from '../contexts/analytics';


export default function LegalWarnig() {

  const nombre_comercial = "Luarca Zabala Hotel"
  const nombre_fiscal = "Abelardo Pérez Monteavaro"
  const cif = "45430906H"
  const direccion = "Aldin S/N"
  const dominio = "https://luarcazabalahotel.com  "
  const email = "info@luarcazabalahotel.com"

  const { i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    window.document.title = "Luarca Zabala Hotel - Aviso Legal"
    save_page_view("Aviso Legal");
  }, [])

  return (
    <>
      <h3>1. <GoogleTranslate textToTranslate={"Datos de la organización"} /></h3>
      <article className='*:text-justify mt-4'>
        <p><GoogleTranslate textToTranslate={"En cumplimiento del Reglamento General de Protección de Datos, se exponen a continuación los datos identificativos de la entidad."} /></p>
        <p><GoogleTranslate textToTranslate={"Nombre"} />: {nombre_comercial}</p>
        <p><GoogleTranslate textToTranslate={"Denominación Social"} />: {nombre_fiscal}</p>
        <p><GoogleTranslate textToTranslate={"Nif / Cif"} />: {cif}</p>
        <p><GoogleTranslate textToTranslate={"Domicilio Social"} />: {direccion}</p>
        <br/>
        <p><GoogleTranslate textToTranslate={"Dirección de correo electrónico"} />: {email}</p>
      </article>
      <h3 className='mt-5'>2. <GoogleTranslate textToTranslate={"Propiedad Intelectual"} /></h3>
      <article className='*:mt-4'>
        <p><GoogleTranslate textToTranslate={"El código fuente, los diseños gráficos, las imágenes, las fotografías, los sonidos, las animaciones, el software, los textos, así como la información y los contenidos  que se recogen en " + nombre_comercial + " están protegidos por " + nombre_comercial + "legislación  española sobre los derechos de propiedad intelectual e industrial a favor de " + nombre_fiscal + " y no se permite la reproducción y/o publicación, total o parcial del sitio web " + dominio + ", ni su tratamiento informático, su distribución, su difusión, ni su modificación, transformación o descompilación, ni demás derechos reconocidos legalmente a su titular, sin el permiso previo y por escrito del mismo."} /></p> 
        <p><GoogleTranslate textToTranslate={"El usuario, única y exclusivamente, puede utilizar el material que aparezca en este sitio web para su uso personal y privado, quedando prohibido su uso con fines comerciales o para incurrir en actividades ilícitas. Todos los derechos derivados de la propiedad intelectual están expresamente reservados por " + nombre_fiscal + "."} /></p>
        <p><GoogleTranslate textToTranslate={nombre_fiscal + "velará por el cumplimiento de las anteriores condiciones como por la debida utilización de los contenidos presentados en sus páginas web, ejercitando todas las acciones civiles y penales que le correspondan en el caso de infracción o incumplimiento de estos derechos por parte del usuario."}/></p>
      </article>
      <h3 className='mt-5'>3. <GoogleTranslate textToTranslate={"Varios"} /></h3>
      <article className='*:text-justify *:mt-4 '>
        <p>A.- {nombre_fiscal} <GoogleTranslate textToTranslate={"podrá modificar, sin previo aviso, la información contenida en su sitio web"} /> {dominio}, <GoogleTranslate textToTranslate={"así como su configuración y presentación"} />.</p>
        <p>B.- {nombre_fiscal} <GoogleTranslate textToTranslate={"se compromete a través de este medio a no realizar publicidad engañosa. A estos efectos, por lo tanto, no serán considerados como publicidad engañosa los errores formales o numéricos que puedan encontrarse a lo largo del contenido de las distintas secciones de la web de"} /> {nombre_comercial}, <GoogleTranslate textToTranslate={"producidos como consecuencia de un mantenimiento y/o actualización incompleta o defectuosa de la información contenida es estas secciones"} />. {nombre_fiscal}, <GoogleTranslate textToTranslate={"como consecuencia de lo dispuesto en este apartado, se compromete a corregirlo tan pronto como tenga conocimiento de dichos errores"} />.</p>
        <p>C.- {nombre_fiscal} <GoogleTranslate textToTranslate={"se compromete a no remitir comunicaciones comerciales sin identificarlos como tales, conforme a lo dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Información y de comercio electrónico. A estos efectos no será considerado como comunicación comercial toda la información que se envíe al usuario, siempre que tenga por finalidad el mantenimiento de la relación contractual existente entre usuario y"} /> {nombre_fiscal}, <GoogleTranslate textToTranslate={"así como el desempeño de las tareas de información, formación y otras actividades propias del servicio que el usuario tiene acordado con la entidad."} /></p>
        <p>D.- {nombre_fiscal} <GoogleTranslate textToTranslate={"no se hace responsable del incumplimiento de cualquier norma aplicable en que pueda incurrir el usuario en su acceso al sitio web"} /> {dominio} <GoogleTranslate textToTranslate={"y/o en el uso de las informaciones contenidas en el mismo"} />.</p>
        <p>E.- {nombre_fiscal} <GoogleTranslate textToTranslate={"no será responsable de los daños y perjuicios producidos o que puedan producirse, cualquiera que sea su naturaleza, que se deriven del uso de la información, de las materias contenidas en este website y de los programas que incorpora. Los enlaces (Links) e hipertexto que posibiliten, a través de"} /> {dominio}, <GoogleTranslate textToTranslate={"acceder al usuario a prestaciones y servicios ofrecidos por terceros, no pertenecen ni se encuentran bajo el control de"} /> {nombre_fiscal}; <GoogleTranslate textToTranslate={"dicha entidad no se hace responsable ni de la información contenida en los mismos ni de cualesquiera efectos que pudieran derivarse de dicha información"} />.</p>
        <p>F.- {nombre_fiscal} <GoogleTranslate textToTranslate={"no se hace responsable del uso ilegítimo que terceras personas puedan hacer de los nombres de marca, nombres de producto, marcas comerciales que, no siendo propiedad de dicha entidad, aparezcan en"}/> {dominio}. <GoogleTranslate textToTranslate={"Tampoco se responsabiliza de la integridad, veracidad y licitud del contenido de los enlaces a las webs a las que pueda accederse desde"} /> {dominio}.</p>
        <p>G.- {nombre_fiscal} <GoogleTranslate textToTranslate={"no se responsabiliza de los virus que tengan su origen en una transmisión telemática infiltrados por terceras partes (por ejemplo, los macros de procesadores de texto, los applets de Java y los programas Active X), generados con la finalidad de obtener resultados negativos para un sistema informático."} /> </p>
        <p>H.- <GoogleTranslate textToTranslate={"En definitiva, el usuario es el único responsable del uso que realice de los servicios, contenidos, enlaces (links) e hipertexto incluidos en el sitio"} /> {dominio}.</p>
      </article>
    </>
  )
}
