import React, { useEffect } from 'react'

import { Buscador, OtrosEstablecimientos, Slider, Ofertas } from '../components'

import { FacilitiesIcon, IconoZabala, LocationIcon, LocationIcon2, ServicesIcon, BedroomIcon } from '../resources'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { save_page_view } from '../contexts/analytics'

const imagenes = [
  {
    src: process.env.REACT_APP_STATIC_URL + "/8FD50BAC-FB25-4C02-B4F1-66254FE834BB.jpeg",
    "alt": "Habitacion 1"
  },
  {
    src: process.env.REACT_APP_STATIC_URL + "/882B3FA0-522E-477F-8F74-99D9FC10C7B8.JPG",
    "alt": "Habitacion 2"
  }
]


export default function Home() {

  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    window.document.title = "Luarca Zabala Hotel - Inicio"
    save_page_view("Inicio");
  }, [])

  function reservar() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <div className=''>
      <section className='relative h-[300px] bg-no-repeat bg-cover bg-center rounded-lg' style={{"backgroundImage": "url(" + process.env.REACT_APP_STATIC_URL + "/luarcaza.jpeg" + ")"}}>
        {/*<img src={process.env.REACT_APP_STATIC_URL + "/P4040331.jpeg"} alt="Zabala Hotel" className='w-full h-full object-cover rounded-lg'/>*/}
        <Buscador className={"absolute top-[50%] left-[50%] transform translate-x-[-50%] -translate-y-[50%] w-full text-white"}/>
      </section>
      <section className='mt-[60px]'>
        <div className='flex justify-between lg:flex-row flex-col gap-5'>
          <div>
            <p className='flex items-end min-w-min'>
              <span className='flex items-end text-primary pr-5 text-2xl'>{t("welcome")}</span>
              <img src={IconoZabala} alt='Zabala Hotel' />
            </p>
            <h1 className='text-primary text-5xl'>Luarca Zabala Hotel</h1>
            <article className='min-h-[600px]'>
              <p className='pt-5 text-justify pr-5'>{t("hotel_introduction_text_p1")}</p>
              <p className='pt-5 text-justify pr-5'>{t("hotel_introduction_text_p2")}</p>
              <p className='pt-5 text-justify pr-5'>{t("hotel_introduction_text_p3")}</p>
              <p className='pt-5 text-justify pr-5'>{t("hotel_introduction_text_p4")}</p>
              <p className='pt-5 text-justify pr-5 min-h-[40px]'>{t("hotel_introduction_text_p5")}</p>
              <p className='text-primary font-bold pt-7'>{t("dream_getaway")}</p>
            </article>
            <Link to="/bookings">
              <div className='bg-primary text-white w-[126px] py-2 mt-5 rounded-lg text-center'>{t("booking")}</div>
            </Link>
          </div>
          <img src={process.env.REACT_APP_STATIC_URL + "/P4040325.jpeg"} alt="Zabala Hotel" className='lg:w-1/2 w-full lg:h-[500px] lg:mt-[165px] object-cover rounded-lg'/>
        </div>
      </section>
      <section className='grid gap-6 grid-cols-[repeat(auto-fill,minmax(278px,1fr))] mt-[60px]'>
        <div className='bg-primary text-white p-5 rounded-lg'>
          <h2 className='text-3xl text-center w-full h-[72px]'>{t("services")}</h2>
          <div className='flex justify-center w-full'>
            <img src={ServicesIcon} alt="Sevices Icon" className='py-5'/>
          </div>
          <div className='flex justify-center pt-[5px]'>
            <Link to="/servicios">
              <div className='bg-primary text-white hover:bg-white hover:text-primary border-2 border-white w-[126px] py-2 mt-5 rounded-lg cursor-pointer text-center'>{t("show_more")}</div>
            </Link>
          </div>
        </div>
        <div className='bg-primary text-white p-5 rounded-lg'>
          <h2 className='text-3xl text-center w-full'>{t("location_environment")}</h2>
          <div className='flex justify-center w-full'>
            <img src={LocationIcon2} alt="Location Icon" className='py-5'/>
          </div>
          <div className='flex justify-center  pt-[5px]'>
            <Link to="/location">
              <div className='bg-primary text-white hover:bg-white hover:text-primary border-2 border-white w-[126px] py-2 mt-5 rounded-lg cursor-pointer text-center'>{t("show_more")}</div>
            </Link>
          </div>
        </div>
        <div className='bg-primary text-white p-5 rounded-lg'>
          <h2 className='text-3xl text-center w-full h-[72px]'>{t("room_rates")}</h2>
          <div className='flex justify-center w-full'>
            <img src={BedroomIcon} alt="Bedroom Icon" className='py-5'/>
          </div>
          <div className='flex justify-center  pt-[11px]'>
            <Link to="/rates">
              <div className='bg-primary text-white hover:bg-white hover:text-primary border-2 border-white w-[126px] py-2 mt-5 rounded-lg cursor-pointer text-center'>{t("show_more")}</div>
            </Link>
          </div>
        </div>
        <div className='bg-primary text-white p-5 rounded-lg'>
          <h2 className='text-3xl text-center w-full h-[72px]'>{t("facilities")}</h2>
          <div className='flex justify-center w-full'>
            <img src={FacilitiesIcon} alt="Facilities Icon" className='py-5'/>
          </div>
          <div className='flex justify-center'>
            <Link to="/facilities">
              <div className='bg-primary text-white hover:bg-white hover:text-primary border-2 border-white w-[126px] py-2 mt-5 rounded-lg cursor-pointer text-center'>{t("show_more")}</div>
            </Link>
          </div>
        </div>
      </section>
      <section className='pt-[60px]'>
        <h2 className='flex items-end text-primary pr-5 text-3xl mb-5'>{t("our_bedrooms")}</h2>
        <Slider 
          imgs={imagenes}
          delay={5000}
          imgClassName='w-full h-[500px] object-cover rounded-lg'  
        />
      </section>
      <section className='pt-[60px]'>
        <h2 className='flex items-end text-primary pr-5 text-3xl mb-5'>{t("find_us_here")}</h2>
        <div className='flex gap-5'>
          <iframe title='Mapa' className='lg:w-1/2 w-full h-[409px] lg:h-[600px] rounded-lg border-2 transition-all' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4842.280145004403!2d-6.5258084535697!3d43.53573692354257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3156d1eb66f283%3A0xa72e7b41d6d3c5!2sHOTEL%20ZABALA!5e0!3m2!1ses!2ses!4v1709980901959!5m2!1ses!2ses" />
          <div className='w-1/2 hidden lg:block'>
            <Buscador />
          </div>
        </div>
        <div className='flex justify-center mt-5 lg:hidden '>
          <Buscador className={"w-full"} />
        </div>
      </section>
      {/*
      <div className='absolute top-[150px] left-[30px] bg-primary text-white p-6 rounded-full'>
        <p><span className="text-xl hover:scale-105">Luarca Experience</span> <br/> Apartemento</p>
      </div>
      <div className='absolute top-[350px] left-[30px] bg-primary text-white p-6 rounded-full'>
        <p><span className="text-xl hover:scale-105">Luarca Experience</span> <br/> Apartemento</p>
      </div>
      */}
      <section className='pt-[60px]'>
        <OtrosEstablecimientos />
      </section>
      {/*
      <section className='pt-[60px]'>
        <Ofertas />
      </section>
      */}
    </div>
  )
}

/*
RESERVAR
*/