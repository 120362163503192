import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { GoogleTranslate } from '../components';
import { save_page_view } from '../contexts/analytics';

export default function Servicios() {

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        window.document.title = "Luarca Zabala Hotel - Servicios"
        save_page_view("Servicios");
      }, [])

    const servicios = [
        {
            "nombre": t("room"),
            "icono": <Room  className={"size-[20px] dark:fill-white"}/>,
            "componentes": ["Armario", "Television", "Calefacion"]
        },
        {
            "nombre": t("bathroom"),
            "icono": <Toilet className={"size-[20px] dark:fill-white"} />,
            "componentes": ["Baño privado", "Artículos de aseo gratis", "Bañera o ducha", "Secador de pelo"]
        },
        {
            "nombre": t("reception_services"),
            "icono": <Reception  className={"size-[20px] dark:fill-white"}/>,
            "componentes": ["Información turística", "Guardaequipaje", "Entrada Autónoma", "Recepción 24 horas"]
        },
        {
            "nombre": "Idiomas Que Se Hablan",
            "icono": <IdiomasQueSeHablan  className={"size-[20px] dark:fill-white"}/>,
            "componentes": ["Español", "Inglés", "Francés"]
        },
        {
            "nombre": "General",
            "icono": <GeneralIcon  className={"size-[20px] dark:fill-white"}/>,
            "componentes": ["Prohibido fumar en todo el alojamiento", "Calefacción", "Habitaciones sin humo"]
        },
        {
            "nombre": t("living_area"),
            "icono": <LivingArea  className={"size-[20px] dark:fill-white"}/>,
            "componentes": ["Minibar"]
        },
        {
            "nombre": t("internet"),
            "icono": <Wifi  className={"size-[20px] dark:fill-white"}/>,
            "componentes": ["Hay conexión a internet Wi-Fi disponible en todo el establecimiento. Gratis."]
        },
        {
            "nombre": t("parking"),
            "icono": <Parking  className={"size-[20px] dark:fill-white"}/>,
            "componentes": ["Hay parking privado dentro del recinto"]
        }
    ]
      
    return (
        <>
            <section>
                <h1 className='text-2xl'><GoogleTranslate textToTranslate={"Servicios"} /></h1>
                <div className='grid gap-6 grid-cols-[repeat(auto-fill,minmax(300px,1fr))]'>
                    {
                        servicios.map((servicio, index) => (
                            <div key={index} className='w-full hover:shadow-md p-4 rounded-lg border border-box_border text-black bg-box dark:bg-box_dark dark:text-white dark:border-box_border_dark shadow cursor-pointer transition-all'>
                                <div className='flex items-center gap-2'>
                                    <p>{servicio.icono}</p>
                                    <h3 className='text-sm font-semibold'><GoogleTranslate textToTranslate={servicio.nombre} /></h3>
                                </div>
                                <ul className='my-4'>
                                    {
                                        servicio.componentes.map((componente, index) => (
                                            <li key={index} className='text-sm flex mt-2'>
                                                <span className='mr-3'>
                                                    <svg className='size-[20px] dark:fill-white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128"><path d="M56.33 100a4 4 0 0 1-2.82-1.16L20.68 66.12a4 4 0 1 1 5.64-5.65l29.57 29.46 45.42-60.33a4 4 0 1 1 6.38 4.8l-48.17 64a4 4 0 0 1-2.91 1.6z"></path></svg>
                                                </span>
                                                <span><GoogleTranslate textToTranslate={componente} /></span>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        ))
                    }
                </div>
            </section>
        </>
    )
}

function Toilet({className}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.75 15h10.5l-.74-.873-.664 3.986a5.25 5.25 0 0 1-5.179 4.387H7.333a5.25 5.25 0 0 1-5.18-4.387l-.663-3.986L.75 15h4.5a.75.75 0 0 0 0-1.5H.75a.75.75 0 0 0-.74.873l.664 3.986A6.75 6.75 0 0 0 7.334 24h9.333a6.75 6.75 0 0 0 6.659-5.64l.664-3.987a.75.75 0 0 0-.74-.873h-10.5a.75.75 0 0 0 0 1.5zM12 19.5H6v-5.25a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v5.25zm0 1.5a1.5 1.5 0 0 0 1.5-1.5v-5.25A2.25 2.25 0 0 0 11.25 12h-4.5a2.25 2.25 0 0 0-2.25 2.25v5.25A1.5 1.5 0 0 0 6 21h6zm4.5-17.25a2.25 2.25 0 0 1 4.5 0v10.5a.75.75 0 0 0 1.5 0V3.75a3.75 3.75 0 1 0-7.5 0 .75.75 0 0 0 1.5 0zm-3 3a2.25 2.25 0 0 1 4.5 0l.75-.75h-6l.75.75zm-1.5 0c0 .414.336.75.75.75h6a.75.75 0 0 0 .75-.75 3.75 3.75 0 1 0-7.5 0z"></path></svg>
    )
}

function Room({className}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2.75 12h18.5c.69 0 1.25.56 1.25 1.25V18l.75-.75H.75l.75.75v-4.75c0-.69.56-1.25 1.25-1.25zm0-1.5A2.75 2.75 0 0 0 0 13.25V18c0 .414.336.75.75.75h22.5A.75.75 0 0 0 24 18v-4.75a2.75 2.75 0 0 0-2.75-2.75H2.75zM0 18v3a.75.75 0 0 0 1.5 0v-3A.75.75 0 0 0 0 18zm22.5 0v3a.75.75 0 0 0 1.5 0v-3a.75.75 0 0 0-1.5 0zm-.75-6.75V4.5a2.25 2.25 0 0 0-2.25-2.25h-15A2.25 2.25 0 0 0 2.25 4.5v6.75a.75.75 0 0 0 1.5 0V4.5a.75.75 0 0 1 .75-.75h15a.75.75 0 0 1 .75.75v6.75a.75.75 0 0 0 1.5 0zm-13.25-3h7a.25.25 0 0 1 .25.25v2.75l.75-.75h-9l.75.75V8.5a.25.25 0 0 1 .25-.25zm0-1.5A1.75 1.75 0 0 0 6.75 8.5v2.75c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75V8.5a1.75 1.75 0 0 0-1.75-1.75h-7z"></path></svg>
    )
}

function Activities({className}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6 11.019V.75l-1.085.67L9.13 3.53a.236.236 0 0 1-.001-.422l-1.965.982a.75.75 0 0 0 .67 1.342l1.966-.983a1.264 1.264 0 0 0 .001-2.26L5.585.079A.75.75 0 0 0 4.5.75v10.269a.75.75 0 0 0 1.5 0zm16.506-5.116l-2.165 16.6h-2.716a1.125 1.125 0 0 1 0-2.25c.535 0 1.051.201 1.445.564l1.623 1.487a.75.75 0 1 0 1.014-1.106l-1.622-1.486a3.632 3.632 0 0 0-2.46-.959 2.625 2.625 0 0 0 0 5.25h2.717a1.5 1.5 0 0 0 1.487-1.306l2.165-16.6a.75.75 0 1 0-1.488-.194zM12 21a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm1.5 0A2.25 2.25 0 1 0 9 21a2.25 2.25 0 0 0 4.5 0zM8.204 9.022c3.054.187 5.296 1.57 5.296 2.978 0 1.538-2.631 3-6 3s-6-1.462-6-3c.055-.68.475-1.34 1.12-1.704a.75.75 0 0 0-.74-1.306 3.721 3.721 0 0 0-1.878 2.952C0 14.604 3.412 16.5 7.5 16.5c4.087 0 7.5-1.896 7.5-4.5 0-2.42-2.96-4.246-6.704-4.476a.75.75 0 1 0-.092 1.498z"></path></svg>
    )
}

function LivingArea({className}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.75 8.625a2.25 2.25 0 0 0-2.25 2.25v1.5h-15v-1.5a2.25 2.25 0 0 0-4.5 0v3a3.75 3.75 0 0 0 3.75 3.75h16.5a3.75 3.75 0 0 0 3.75-3.75v-3a2.25 2.25 0 0 0-2.25-2.25zm0 1.5a.75.75 0 0 1 .75.75v3a2.25 2.25 0 0 1-2.25 2.25H3.75a2.25 2.25 0 0 1-2.25-2.25v-3a.75.75 0 0 1 1.5 0v1.5a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-1.5a.75.75 0 0 1 .75-.75zM3 16.875v2.25a.75.75 0 0 0 1.5 0v-2.25a.75.75 0 0 0-1.5 0zm16.5 0v2.25a.75.75 0 0 0 1.5 0v-2.25a.75.75 0 0 0-1.5 0zM3 9.375a3.75 3.75 0 0 1 3.75-3.75h10.5A3.75 3.75 0 0 1 21 9.375a.75.75 0 0 0 1.5 0c0-2.9-2.35-5.25-5.25-5.25H6.75a5.25 5.25 0 0 0-5.25 5.25.75.75 0 0 0 1.5 0zm9.75 3.75v-8.25a.75.75 0 0 0-1.5 0v8.25a.75.75 0 0 0 1.5 0z"></path></svg>
    )
}

function Technology({className}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.5 10.375v6.5a.25.25 0 0 1-.25.25H1.75a.25.25 0 0 1-.25-.25v-13a.25.25 0 0 1 .25-.25h20.5a.25.25 0 0 1 .25.25v6.5zm1.5 0v-6.5a1.75 1.75 0 0 0-1.75-1.75H1.75A1.75 1.75 0 0 0 0 3.875v13c0 .966.784 1.75 1.75 1.75h20.5a1.75 1.75 0 0 0 1.75-1.75v-6.5zm-16.5 12h9a.75.75 0 0 0 0-1.5h-9a.75.75 0 0 0 0 1.5zm3.75-4.5v3.75a.75.75 0 0 0 1.5 0v-3.75a.75.75 0 0 0-1.5 0z"></path></svg>
    )
}

function Wifi({className}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14.25 18.75a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0zm1.5 0a3.75 3.75 0 1 0-7.5 0 3.75 3.75 0 0 0 7.5 0zm2.08-5.833a8.25 8.25 0 0 0-11.666 0 .75.75 0 0 0 1.06 1.06 6.75 6.75 0 0 1 9.546 0 .75.75 0 0 0 1.06-1.06zm3.185-3.182c-4.979-4.98-13.051-4.98-18.03 0a.75.75 0 1 0 1.06 1.06c4.394-4.393 11.516-4.393 15.91 0a.75.75 0 1 0 1.06-1.06zm2.746-3.603C17.136-.043 6.864-.043.24 6.132A.75.75 0 1 0 1.26 7.23c6.05-5.638 15.429-5.638 21.478 0a.75.75 0 0 0 1.022-1.098z"></path></svg>
    )
}

function FoodAndDrink({className}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.999.75v22.5a.75.75 0 0 0 1.5 0V.75a.75.75 0 0 0-1.5 0zm3 0V7.5a2.259 2.259 0 0 1-2.252 2.25 2.258 2.258 0 0 1-2.248-2.252V.75a.75.75 0 0 0-1.5 0V7.5a3.76 3.76 0 0 0 3.748 3.75 3.76 3.76 0 0 0 3.752-3.748V.75a.75.75 0 0 0-1.5 0zm6.75 15.75h3c1.183.046 2.203-.9 2.25-2.111a2.22 2.22 0 0 0 0-.168c-.25-6.672-.828-9.78-3.231-13.533a1.508 1.508 0 0 0-2.77.81V23.25a.75.75 0 0 0 1.5 0V1.503c0 .003.001 0 .003 0a.006.006 0 0 1 .008.002c2.21 3.45 2.75 6.354 2.99 12.773v.053a.696.696 0 0 1-.721.67L15.749 15a.75.75 0 0 0 0 1.5z"></path></svg>
    )
}

function Parking({className}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12zm-9.75-1.5a1.5 1.5 0 0 1-1.5 1.5H10.5l.75.75v-4.5L10.5 9h2.25a1.5 1.5 0 0 1 1.5 1.5zm1.5 0a3 3 0 0 0-3-3H10.5a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75h2.25a3 3 0 0 0 3-3zm-4.5 6.75v-4.5a.75.75 0 0 0-1.5 0v4.5a.75.75 0 0 0 1.5 0z"></path></svg>
    )
}

function TransportePublico({className}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.75 1.513h12.5c.69 0 1.25.56 1.25 1.25v13.5a.25.25 0 0 1-.25.25H4.75a.25.25 0 0 1-.25-.25v-13.5c0-.69.56-1.25 1.25-1.25zm0-1.5A2.75 2.75 0 0 0 3 2.763v13.5c0 .966.784 1.75 1.75 1.75h14.5a1.75 1.75 0 0 0 1.75-1.75v-13.5a2.75 2.75 0 0 0-2.75-2.75H5.75zm-3.65 23.7l2.25-3a.75.75 0 1 0-1.2-.9l-2.25 3a.75.75 0 1 0 1.2.9zm21-.9l-2.25-3a.75.75 0 1 0-1.2.9l2.25 3a.75.75 0 1 0 1.2-.9zm-19.35-12.3h16.5a.75.75 0 0 0 0-1.5H3.75a.75.75 0 0 0 0 1.5zm7.5-9.75v9a.75.75 0 0 0 1.5 0v-9a.75.75 0 0 0-1.5 0zm-7.5 12.75H7.5a.75.75 0 0 0 0-1.5H3.75a.75.75 0 0 0 0 1.5zm16.5-1.5H16.5a.75.75 0 0 0 0 1.5h3.75a.75.75 0 0 0 0-1.5zM8.92 17.598l1.5-3-.67.415h4.5l-.67-.415 1.5 3a.75.75 0 1 0 1.34-.67l-1.5-3a.75.75 0 0 0-.67-.415h-4.5a.75.75 0 0 0-.67.415l-1.5 3a.75.75 0 1 0 1.34.67z"></path></svg>
    )
}

function IdiomasQueSeHablan({className}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 13.5a6 6 0 0 0-6-6h-3a6 6 0 0 0 0 12h.75l-.53-.22 4.5 4.5a.75.75 0 0 0 1.28-.53v-5.024l-.43.678A5.989 5.989 0 0 0 24 13.502zm-1.5-.002a4.489 4.489 0 0 1-2.57 4.05.75.75 0 0 0-.43.678v5.024l1.28-.53-4.5-4.5a.75.75 0 0 0-.53-.22H15a4.5 4.5 0 1 1 0-9h3a4.5 4.5 0 0 1 4.5 4.5zM6.22 12.22l-3 3 1.28.53v-5.024a.75.75 0 0 0-.43-.678A4.489 4.489 0 0 1 5.998 1.5H9a4.502 4.502 0 0 1 4.313 3.214.75.75 0 0 0 1.438-.428A6.002 6.002 0 0 0 9 0H6a5.988 5.988 0 0 0-2.57 11.404L3 10.726v5.024a.75.75 0 0 0 1.28.53l3-3a.75.75 0 1 0-1.06-1.06z"></path></svg>
    )
}

function GeneralIcon({className}) { 
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14.25 15.75h-.75a.75.75 0 0 1-.75-.75v-3.75a1.5 1.5 0 0 0-1.5-1.5h-.75a.75.75 0 0 0 0 1.5h.75V15a2.25 2.25 0 0 0 2.25 2.25h.75a.75.75 0 0 0 0-1.5zM11.625 6a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z"></path></svg>
    )
}

function Reception({className}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14.244 14.156a6.75 6.75 0 0 0-6.75-5.906A6.747 6.747 0 0 0 .73 14.397a.75.75 0 0 0 1.494.134 5.25 5.25 0 0 1 5.27-4.781 5.253 5.253 0 0 1 5.262 4.594.75.75 0 1 0 1.488-.188zM10.125 4.125a2.625 2.625 0 1 1-5.25 0V1.5h5.25v2.625zm1.5 0V1.5a1.5 1.5 0 0 0-1.5-1.5h-5.25a1.5 1.5 0 0 0-1.5 1.5v2.625a4.125 4.125 0 0 0 8.25 0zM23.25 22.5H.75l.75.75v-7.5a.75.75 0 0 1 .75-.75h19.5a.75.75 0 0 1 .75.75v7.5l.75-.75zm0 1.5a.75.75 0 0 0 .75-.75v-7.5a2.25 2.25 0 0 0-2.25-2.25H2.25A2.25 2.25 0 0 0 0 15.75v7.5c0 .414.336.75.75.75h22.5zM4.376 5.017a9.42 9.42 0 0 1 3.12-.517 9.428 9.428 0 0 1 3.133.519.75.75 0 0 0 .49-1.418A10.917 10.917 0 0 0 7.498 3a10.91 10.91 0 0 0-3.611.6.75.75 0 0 0 .49 1.417zM15.75 14.27a3.001 3.001 0 0 1 6 .16.75.75 0 1 0 1.5.04 4.501 4.501 0 1 0-9-.24.75.75 0 1 0 1.5.04zm3.75-3.77V8.25a.75.75 0 0 0-1.5 0v2.25a.75.75 0 0 0 1.5 0zM17.25 9h3a.75.75 0 0 0 0-1.5h-3a.75.75 0 0 0 0 1.5z"></path></svg>
    )
}