import React, { useState, useEffect, useRef } from 'react'
import { toast } from 'sonner';

export default function DatePicker({text, onSelectDate, dateBeforeDisabled}) {

    const [date, setDate] = useState(null);

    const [selectDate, setSelectDate] = useState(false)

    // Calendario View
    const [calendarioDate, setCalendarioDate] = useState(new Date())

    useEffect(() => {

        onSelectDate(date)

    }, [date])

    function next() {
        setCalendarioDate(new Date(calendarioDate.setMonth(calendarioDate.getMonth() + 1)))
    }

    function before() {
        setCalendarioDate(new Date(calendarioDate.setMonth(calendarioDate.getMonth() - 1)))
    }

    function primerDia() {
        return new Date(calendarioDate.getFullYear(), calendarioDate.getMonth(), 1)
    }

    function ultimoDiaMesAnterior() {
        return new Date(calendarioDate.getFullYear(), calendarioDate.getMonth(), 0);
    }

    function primerDiaMesSiguiente() {
        return new Date(calendarioDate.getFullYear(), calendarioDate.getMonth() + 1, 1);
    }

    function diasEnMes(anio, mes) {
        return new Date(anio, mes, 0).getDate();
    }

    function stringDate() {
        if (date != null) {
            return date.getDate().toString().padStart(2, "0") + "/" + (date.getMonth() + 1).toString().padStart(2, "0") + "/" + date.getFullYear()
        }
    }

    
    const datePickerRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                //setSelectDate(false);
                
                setSelectDate(false)

            }
        }

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };

    }, []);

    return (
        <>
            <button className="bg-box border-box_border hover:bg-body w-full inline-flex items-center whitespace-nowrap rounded-md text-sm text-black ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 md:w-[280px] justify-start text-left font-normal text-muted-foreground" type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:rbp:" data-state="closed" 
                onClick={(event) => {
                    setSelectDate(!selectDate)
                    event.stopPropagation()
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-calendar mr-2 h-4 w-4">
                    <path d="M8 2v4"></path>
                    <path d="M16 2v4"></path>
                    <rect width="18" height="18" x="3" y="4" rx="2"></rect>
                    <path d="M3 10h18"></path>
                </svg>
                <span>{date != null ? stringDate() : text}</span>
            </button>

            <div ref={datePickerRef} className={(selectDate ? "flex" : "hidden") + " flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 mt-3 absolute z-20"}>
                <div className="space-y-4 shadow-md p-2 rounded-lg border border-box_border text-black bg-box cursor-pointer transition-all">

                    <div className="flex justify-center pt-1 relative items-center">
                        <div className="text-sm font-medium" aria-live="polite" role="presentation" id="react-day-picker-28">{getMes(calendarioDate.getMonth())} {calendarioDate.getFullYear()}</div>
                        <div className="space-x-1 flex items-center">
                            <button name="previous-month" aria-label="Go to previous month" className="  inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:bg-accent hover:text-accent-foreground h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 absolute left-1" type="button" onClick={before}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-left h-4 w-4">
                                    <path d="m15 18-6-6 6-6"></path>
                                </svg>
                            </button>
                            <button name="next-month" aria-label="Go to next month" className="  inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:bg-accent hover:text-accent-foreground h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 absolute right-1" type="button" onClick={next}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-right h-4 w-4">
                                    <path d="m9 18 6-6-6-6"></path>
                                </svg>
                            </button>        
                        </div>
                    </div>

                    <div className="grid grid-cols-7 w-full mt-2">
                        <p scope="col" className="text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]" aria-label="Sunday">{getDiasSemana(1, "s")}</p>
                        <p scope="col" className="text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]" aria-label="Monday">{getDiasSemana(2, "s")}</p>
                        <p scope="col" className="text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]" aria-label="Tuesday">{getDiasSemana(3, "s")}</p>
                        <p scope="col" className="text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]" aria-label="Wednesday">{getDiasSemana(4, "s")}</p>
                        <p scope="col" className="text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]" aria-label="pursday">{getDiasSemana(5, "s")}</p>
                        <p scope="col" className="text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]" aria-label="Friday">{getDiasSemana(6, "s")}</p>
                        <p scope="col" className="text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]" aria-label="Saturday">{getDiasSemana(7, "s")}</p>
                    </div>

                    <div className="grid grid-cols-7 w-full">
                        {
                            Array.from({length: getDia(primerDia().getDay()) -1}, (v, i) => {
                                return (
                                    <p key={v} className="h-9 w-9 text-center text-sm p-0 relative [&amp;:has([aria-selected].day-range-end)]:rounded-r-md [&amp;:has([aria-selected].day-outside)]:bg-accent/50 [&amp;:has([aria-selected])]:bg-accent first:[&amp;:has([aria-selected])]:rounded-l-md last:[&amp;:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20" role="presentation">
                                        <button 
                                            name="day" 
                                            className={"hover:bg-body inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 w-9 p-0 font-normal aria-selected:opacity-100 day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground"} 
                                            role="gridcell" 
                                            tabIndex="-1" 
                                            type="button"
                                            onClick={() => setDate(new Date(calendarioDate.getFullYear(), calendarioDate.getMonth() - 1, ultimoDiaMesAnterior().getDate() + 1))}
                                        >{ultimoDiaMesAnterior().getDate() - i}</button>
                                    </p>
                                )
                            }).reverse()
                        }
                        {
                            Array.from({length: diasEnMes(calendarioDate.getFullYear(), calendarioDate.getMonth() + 1)}, (v, i) => {

                                const fecha = new Date(calendarioDate.getFullYear(), calendarioDate.getMonth(), i + 1)
                                
                                return (
                                    <p className="h-9 w-9 text-center text-sm p-0" role="presentation" key={v}>
                                        <button 
                                            name="day" 
                                            className={ (calendarioDate.getDate() == fecha.getDate() ? "bg-primary text-white" : "") + (dateBeforeDisabled ? (fecha <  new Date().setDate(new Date().getDate() - 1) ? " cursor-not-allowed" : "") : "") + " hover:bg-body hover:border border-primary hover:text-black inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 w-9 p-0 font-normal aria-selected:opacity-100"}
                                            role="gridcell" 
                                            tabIndex="-1" 
                                            type="button"
                                            onClick={() => {
                                                    if (dateBeforeDisabled) {
                                                        if (fecha <  new Date().setDate(new Date().getDate() - 1)) {
                                                            toast.info('La fecha seleccionada no puede ser anterior a la fecha actual.')
                                                        } else {
                                                            setDate(fecha)
                                                            setSelectDate(false)
                                                        }
                                                    } else {
                                                        setDate(fecha)
                                                        setSelectDate(false)
                                                    }
                                                }
                                            }
                                        >{fecha.getDate()}</button>
                                    </p>
                                )   
                            })
                        }
                        {
                            Array.from({length: 8    - primerDiaMesSiguiente().getDay()}, (v, i) => {
                                if (primerDiaMesSiguiente().getDay() != 1) {
                                    return (
                                        <p key={v} className="h-9 w-9 text-center text-sm p-0 relative [&amp;:has([aria-selected].day-range-end)]:rounded-r-md [&amp;:has([aria-selected].day-outside)]:bg-accent/50 [&amp;:has([aria-selected])]:bg-accent first:[&amp;:has([aria-selected])]:rounded-l-md last:[&amp;:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20" role="presentation">
                                            <button 
                                                name="day" 
                                                className={ date != null ? date.getDate() : "" + " hover:bg-body inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 w-9 p-0 font-normal aria-selected:opacity-100 day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30"} 
                                                role="gridcell" 
                                                tabIndex="-1" 
                                                type="button"
                                                onClick={() => setDate(new Date(date.getFullYear(), date.getMonth() - 1, ultimoDiaMesAnterior().getDate() + 1))}
                                            >{i + 1}</button>
                                        </p>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}


function getMes(n) {

    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

    return meses[n]

}

function getDiasSemana(n, sl) {
    
    var dias = []

    if (sl == "s") {
        dias = ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"]
    } else {
        dias = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"]
    }

    return dias[n - 1]
}

function getDia(n) {

    if (n == 0) {
        return 7
    }

    return n

}
