import React, { useEffect } from 'react'
import { save_page_view } from '../contexts/analytics';

const precios_articulos = [
    {
        "nombre": "Agua Grande",
        "precio": 1.50
    },
    {
        "nombre": "Agua Pequeña",
        "precio": 1
    },
    {
        "nombre": "Coca Cola",
        "precio": 1.50
    },
    {
        "nombre": "Cerveza",
        "precio": 1.5
    },
    {
        "nombre": "Zumo",
        "precio": 1.50
    },
    {
        "nombre": "Aquarius",
        "precio": 1.50
    },
    {
        "nombre": "Chocolatinas",
        "precio": 1
    }
]

export default function MiniBar() {

    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        window.document.title = "Luarca Zabala Hotel - Precios MiniBar"
        save_page_view("Precios MiniBar");
    }, [])

    return (
        <>
            <h1 className='text-2xl'>Precios MiniBar</h1>
            <section>
                <div className='mt-5 m-10'>
                    {
                        precios_articulos.map((art, index) => {
                            return (
                                <div key={index} className='flex justify-between mb-5 lg:w-[500px] w-full'>
                                    <h2 className="whitespace-nowrap">{art.nombre}</h2>
                                    <span className='overflow-x-hidden mx-2'>.................................................................................................</span>
                                    <p className='w-[40px]'>{art.precio}€</p>
                                </div>
                            )
                        })
                    }
                </div>
            </section>
        </>
    )
}
