import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { registrar_evento } from '../contexts/analytics';
import { toast } from 'sonner';

const LanguageSelector = ({className}) => {
    const { i18n, t } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        registrar_evento("Cambio de Idioma")    
        toast(t("language_chante") )
    };

    const [ language, setLanguage ] = React.useState(navigator.language.substring(0, 2).toUpperCase());

    useEffect(() => {
        setLanguage(i18n.language.toUpperCase())
    }, [i18n.language])

    return (
        <div className={"flex justify-around items-center min-w-[100px] " + className}>
            <button className={"hover:underline p-1 rounded-lg " + (language == "EN" ? "underline" : "")} onClick={() => changeLanguage('en')}>EN</button>
            <span>|</span>
            <button className={"hover:underline p-1 rounded-lg " + (language == "ES" ? "underline" : "")} onClick={() => changeLanguage('es')}>ES</button>
            <span>|</span>
            <button className={"hover:underline p-1 rounded-lg " + (language == "FR" ? "underline" : "")} onClick={() => changeLanguage('fr')}>FR</button>
        </div>
    );
};

export default LanguageSelector;
