import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { registrar_evento } from '../contexts/analytics';

export default function Cookies() {

    const { t } = useTranslation();

    const [cookies, setCookies] = useState(false)

    function acceptCookies() {
        setCookies(true)
        document.getElementById("cookies_accept").innerHTML = "<script> gtag('event', 'cookies_accept') </script>"
        localStorage.setItem("cookies", "true")
        registrar_evento("Acceptar Cookies")
    }

    useEffect(() => {
        if (localStorage.getItem("cookies") == "true") {
            setCookies(true)
        }
    })

    return (
        <> 
            <section className={(cookies ? "hidden" : "fixed") + " max-w-md p-4 lg:mx-auto bg-box border border-box_border dark:bg-box_dark lg:left-5 lg:bottom-5 left-0 bottom-0 mx-3 mb-3 lg:mb-0 dark:border-box_border_dark rounded-2xl shadow transition-all"}>
                <h2 className="font-semibold text-gray-800 dark:text-white">🍪 {t("cookie_alert_title")}</h2>
                <p className="mt-4 text-sm text-gray-600 dark:text-gray-300">{t("cookie_alert_body")} <Link to="cookies_policy" className="text-blue-500 hover:underline">{t("cookie_alert_link")}</Link>. </p>
                <div className="flex items-center justify-between mt-4 gap-x-4 shrink-0">
                    <span></span>
                    {/*
                    <button className="text-xs text-gray-800 underline transition-colors duration-300 dark:text-white dark:hover:text-gray-400 hover:text-gray-600 focus:outline-none">
                        {t("cookie_alert_manage")}
                    </button>
                    */}
                    <button onClick={() => {
                        acceptCookies()
                    }} className="text-xs bg-gray-900 font-medium rounded-lg hover:bg-gray-700 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none">
                        {t("accept")}
                    </button>
                </div>
                <div id="cookies_accept"></div>
            </section>
        </>
    )
}
