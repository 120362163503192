import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import EmailIcon from '../resources/emailIcon.png'
import LocationIcon from '../resources/locationIcon.png'
import PhoneIcon from '../resources/phoneIcon.png'
import LogoZabala from '../resources/IconoZabalaWhite.png'
import { use } from 'i18next';

export default function Footer() {

    const { t } = useTranslation();

    return (
        <footer className='bg-primary text-[#F5F5F5] z-30 w-full'>
            <section className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row'>
                <div className='*:mt-[30px] mb-5'>
                    <div className='flex items-center gap-2'>
                        <img src={PhoneIcon} alt='Telefono Icono'/>
                        <a href={"tel:" + process.env.REACT_APP_PHONE_NUMBER} className='hover:underline'>
                            <span>+34</span>
                            <span className='ml-2'>{process.env.REACT_APP_PHONE_NUMBER.match(/.{1,3}/g).join(" ")}</span>
                        </a>
                    </div>
                    <div className='flex items-center gap-2'>
                        <img src={EmailIcon} alt='Email Icono'/>
                        <a href={"mailto:" + process.env.REACT_APP_EMAIL_ADDRESS} className='hover:underline'>{process.env.REACT_APP_EMAIL_ADDRESS}</a>
                    </div>
                    <div className='flex items-center gap-2 whitespace-nowrap'>
                        <img src={LocationIcon} alt='Localizacion Icono'/>
                        <a href='https://www.google.es/maps/place/HOTEL+ZABALA/@43.5339776,-6.5286095,17z/data=!3m1!4b1!4m9!3m8!1s0xd3156d1eb66f283:0xa72e7b41d6d3c5!5m2!4m1!1i2!8m2!3d43.5339776!4d-6.5260346!16s%2Fg%2F1tvq5ds7?entry=ttu' className='hover:underline'>Almuña - Luarca, 33700 Valdés (Asturias)</a>
                    </div>
                </div>
                <div className='flex items-center lg:justify-end w-full justify-center mb-5 lg:mb-0 mt-10 lg:mt-0'>
                    <img src={LogoZabala} alt='Luarca Zabala Hotel' className='h-[60px]' />
                </div>
            </section>
            <hr className='bg-white' />
            <section className='flex flex-col lg:flex-row justify-center mt-[20px] gap-3 pb-5'>
                <p className='text-center'>© Copyright {new Date().getFullYear()} Luarca Zabala Hotel</p>
                <span className="hidden lg:block">-</span>
                <Link to='./legal_warning' className="hover:underline text-center">{t('footer_legal_warning')}</Link>
                <span className="hidden lg:block">-</span>
                <Link to='./privacy_policy' className="hover:underline text-center">{t('footer_privacy_policy')}</Link>
                <span className="hidden lg:block">-</span>
                <Link to='./cookies_policy' className="hover:underline text-center">{t('footer_cookies_policy')}</Link>
                {/*
                <span className="hidden lg:block">-</span>
                <Link to='./accessibility' className="hover:underline text-center">{t('footer_accessibility')}</Link>
                */}
            </section>
        </footer>
    )
}
