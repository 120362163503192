import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import { HashRouter as Router } from 'react-router-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import enTranslation from './idiomas/en.json'; // Importa el archivo de traducción para inglés
import esTranslation from './idiomas/es.json'; // Importa el archivo de traducción para español
import frTranslation from './idiomas/fr.json'; // Importa el archivo de traducción para español
import deTranslation from './idiomas/de.json';

import { ContextProvider } from './contexts/ContextProvider';

// Configura el paquete de internacionalización
i18n
  .use(initReactI18next) // Usa react-i18next
  .init({
    resources: {
      en: {
        translation: enTranslation // Asigna las traducciones en inglés
      },
      es: {
        translation: esTranslation // Asigna las traducciones en español
      },
      fr: {
        translation: frTranslation // Asigna las traducciones en francés
      },
      de: {
        translation: deTranslation // Asigna las traducciones en alemán
      }
    },
    lng: 'es', // Establece el idioma por defecto
    fallbackLng: 'en', // Establece el idioma de respaldo
    interpolation: {
      escapeValue: false // No escape los valores
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <ContextProvider>
      <App />
    </ContextProvider>
  </Router>
);