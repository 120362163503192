import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

const initialState = {

};

export const ContextProvider = ({ children }) => {

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
        <StateContext.Provider value={{ showAlert, alertTitle, alertMessage, setAlertMessage, setAlertTitle, setShowAlert }}>
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);