import React, { useEffect, useState, useRef } from 'react'
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { Carousel } from "@material-tailwind/react";

export default function Slider({imgs, imgClassName, delay}) {

    const [imgIndex, setImgIndex] = useState(0)

    const handleNext = () => {
        if (imgIndex === imgs.length - 1) {
            setImgIndex(0)
        } else {
            setImgIndex(imgIndex + 1)
        }
    }

    const handlePrev = () => {
        if (imgIndex === 0) {
            setImgIndex(imgs.length - 1)
        } else {
            setImgIndex(imgIndex - 1)
        }
    }

    const setImg= (index) => {
        setImgIndex(index)
    }

    useEffect(() => {
        const interval = setInterval(handleNext, delay); // Cambiar cada x segundos
    
        return () => {
          clearInterval(interval); // Limpia el intervalo cuando el componente se desmonta
        };
      }, [imgIndex]); // Reiniciar el intervalo cuando el índice cambia

    return (
        <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg lg:overflow-visible">
            <div className="relative w-full h-full overflow-x-hidden flex rounded-xl border-2">
                {/*
                {imgs.map((img, index) => (
                    <div className="w-full h-full inline-block flex-none" style={{left:"0%", right:"0%" ,transform:"none"}} key={index}>
                        <img 
                            ref={ref}
                            src={img.src} 
                            alt={img.alt}
                            className={img} 
                            style={{
                                transform: `translateX(${dimensions.width * imgIndex}px)`,
                            }}   
                        />
                    </div>
                ))}
                */}
                <img 
                    src={imgs[imgIndex].src} 
                    alt={imgs[imgIndex].alt}
                    className={imgClassName}  
                />
                <button onClick={handlePrev} aria-label='Imagen Previa' className="!absolute top-2/4 left-4 -translate-y-2/4 rounded-full select-none transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-12 max-w-[48px] h-12 max-h-[48px] text-white hover:bg-white/10 active:bg-white/30 grid place-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeLinecap="3" className="-ml-1 h-7 w-7">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path>
                    </svg>
                </button>
                <button onClick={handleNext} aria-label='Imagen Siguiente' className="!absolute top-2/4 right-4 -translate-y-2/4 rounded-full select-none transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-12 max-w-[48px] h-12 max-h-[48px] text-white hover:bg-white/10 active:bg-white/30 grid place-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeLinecap="3" className="ml-1 h-7 w-7">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path>
                    </svg>
                </button>
                <div className="absolute bottom-4 left-2/4 z-10 flex -translate-x-2/4 gap-2">
                    {
                        imgs.map((img, index) => (
                            <span onClick={() => setImg(index)} key={index} className={"block h-3 w-3 cursor-pointer rounded-full transition-colors content-[''] " + (index == imgIndex ? "bg-white scale-105" : "bg-white/50")}></span>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
