import React, { useContext, useEffect, useState } from 'react'
import './reservas.css';
import { useTranslation } from 'react-i18next';

import { useStateContext } from '../../contexts/ContextProvider'
import GoogleTranslate from '../GoogleTranslate';
import { registrar_evento } from '../../contexts/analytics';

function obtenerTipoTarjeta(numeroTarjeta) {
    // Eliminar espacios en blanco y guiones del número de tarjeta
    const numeroLimpiado = numeroTarjeta.replace(/[^\d]/g, '');

    // Verificar el tipo de tarjeta según los patrones comunes
    if (/^4/.test(numeroLimpiado)) {
        return <Visa />;
    } else if (/^5[1-5]/.test(numeroLimpiado)) {
        return <Mastercard />;
    } else if (/^3[47]/.test(numeroLimpiado)) {
        return 'American Express';
    } else if (/^6(?:011|5[0-9]{2})/.test(numeroLimpiado)) {
        return 'Discover';
    } else {
        return '';
    }
}

function Mastercard() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="52px" height="32px" viewBox="0 0 52 32" version="1.1">
            <g id="Components---Sprint-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="assets-/-logo-/-mastercard-/-symbol">
                    <polygon id="Fill-1" fill="#FF5F00" points="18.7752605 28.537934 32.6926792 28.537934 32.6926792 3.41596003 18.7752605 3.41596003"/>
                    <path d="M19.6590387,15.976947 C19.6590387,10.8803009 22.03472,6.34107274 25.7341024,3.41596003 C23.0283795,1.27638054 19.6148564,0 15.9044284,0 C7.12054904,0 0.000132546844,7.15323422 0.000132546844,15.976947 C0.000132546844,24.8006598 7.12054904,31.953894 15.9044284,31.953894 C19.6148564,31.953894 23.0283795,30.6775135 25.7341024,28.537934 C22.03472,25.6123775 19.6590387,21.0735931 19.6590387,15.976947" id="Fill-2" fill="#EB001B"/>
                    <path d="M50.9714634,25.8771954 L50.9714634,25.257201 L50.8101981,25.257201 L50.6250744,25.6836968 L50.4395088,25.257201 L50.2782434,25.257201 L50.2782434,25.8771954 L50.3917919,25.8771954 L50.3917919,25.4094258 L50.5658701,25.8128438 L50.6838368,25.8128438 L50.857915,25.4085382 L50.857915,25.8771954 L50.9714634,25.8771954 Z M49.9504109,25.8771954 L49.9504109,25.3628264 L50.157184,25.3628264 L50.157184,25.2580887 L49.6314148,25.2580887 L49.6314148,25.3628264 L49.8377461,25.3628264 L49.8377461,25.8771954 L49.9504109,25.8771954 Z M51.4680723,15.9768139 C51.4680723,24.8005266 44.347214,31.9537609 35.5637764,31.9537609 C31.8533484,31.9537609 28.4393835,30.6773803 25.7341024,28.5378008 C29.4334848,25.6126881 31.8091661,21.07346 31.8091661,15.9768139 C31.8091661,10.8806116 29.4334848,6.34138341 25.7341024,3.41582689 C28.4393835,1.2762474 31.8533484,-0.000133141225 35.5637764,-0.000133141225 C44.347214,-0.000133141225 51.4680723,7.15310107 51.4680723,15.9768139 L51.4680723,15.9768139 Z" id="Fill-4" fill="#F79E1B"/>
                </g>
            </g>
        </svg>
    )
}

function Visa() {
    return (
        <svg fill="none" className="h-6 text-[#1434CB] dark:text-white" viewBox="0 0 36 21">
            <path fill="currentColor" d="M23.315 4.773c-2.542 0-4.813 1.3-4.813 3.705 0 2.756 4.028 2.947 4.028 4.332 0 .583-.676 1.105-1.832 1.105-1.64 0-2.866-.73-2.866-.73l-.524 2.426s1.412.616 3.286.616c2.78 0 4.966-1.365 4.966-3.81 0-2.913-4.045-3.097-4.045-4.383 0-.457.555-.957 1.708-.957 1.3 0 2.36.53 2.36.53l.514-2.343s-1.154-.491-2.782-.491zM.062 4.95L0 5.303s1.07.193 2.032.579c1.24.442 1.329.7 1.537 1.499l2.276 8.664h3.05l4.7-11.095h-3.043l-3.02 7.543L6.3 6.1c-.113-.732-.686-1.15-1.386-1.15H.062zm14.757 0l-2.387 11.095h2.902l2.38-11.096h-2.895zm16.187 0c-.7 0-1.07.37-1.342 1.016L25.41 16.045h3.044l.589-1.68h3.708l.358 1.68h2.685L33.453 4.95h-2.447zm.396 2.997l.902 4.164h-2.417l1.515-4.164z"/>
        </svg>
    )
}



export default function Reservas() {

    const { t } = useTranslation();
    const { setAlertMessage, setAlertTitle, setShowAlert } = useStateContext();

    function buscarDisponobilidad() {

        registrar_evento("Buscar Disponobilidad")

        var fechaini = document.getElementById('fechaini').value;
        var fechafin = document.getElementById('fechafin').value;

        if (fechaini == "" || fechafin == "") {
            setShowAlert(true);
            setAlertTitle('Error');
            setAlertMessage('Las fechas no pueden estar vacías.');
        } else {

            const metodo = sessionStorage.getItem("metodo")
            const porcentaje = sessionStorage.getItem("porcentaje")
    
            sessionStorage.clear();
    
            sessionStorage.setItem('metodo', metodo);
            sessionStorage.setItem('porcentaje', porcentaje);
            sessionStorage.setItem('fechaini', fechaini);
            sessionStorage.setItem('fechafin', fechafin);
            sessionStorage.setItem('diasreserva', (new Date(fechafin) - new Date(fechaini)) / (1000 * 60 * 60 * 24));
            sessionStorage.setItem('fechaini', fechaini);
            var url = 'https://api.lemurgh.com/reservas/v3/api.php?action=disponibilidad&usuario=zabala&fechaini='+fechaini+'&fechafin='+fechafin;
            var ajax = new XMLHttpRequest();
            ajax.open('GET', url, true);
            ajax.onreadystatechange = function() {
                if (ajax.readyState == 4 && ajax.status == 200) {
                    document.getElementById('respuesta').innerHTML = ajax.responseText + `<script>gtag('event', 'buscar_reserva')</script>`;
                    document.getElementById('respuesta').style.display = '';
                }
            }
            ajax.send();

        }
    }

    function paso1() {
        document.getElementById('paso1').style.display = '';
        document.getElementById('paso2').style.display = 'none';
    }

    useEffect(() => {
        if (sessionStorage.getItem("fechaini") && sessionStorage.getItem("fechafin")) {
            document.getElementById("fechaini").value = sessionStorage.getItem("fechaini");
            document.getElementById("fechafin").value = sessionStorage.getItem("fechafin");
            buscarDisponobilidad();
        }
        document.getElementById('paso2_button').innerHTML = '<input class="btn_siguiente mt-5 hover:scale-105 bg-primary cursor-pointer transition-all" type="button" value="Continuar" onClick="paso3()" id="irpaso3"/>';       
        document.getElementById('paso3_button').innerHTML = '<button class="btn_siguiente" type="button" id="confirmar" onClick="paso4()"">¡Confirmar reserva!</button>'

    }, [])

    const [nTargeta, setNTargeta] = useState('');

    const tarjetaOnkeyup = (e) => {
        setNTargeta(e.target.value);
        const valor = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
        e.target.value = valor
        
        //Guardar el valor en el sessionStorage cada 4 digitos
        sessionStorage.setItem("tarjeta_num_1", valor.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').split(' ')[0])
        sessionStorage.setItem("tarjeta_num_2", valor.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').split(' ')[1])
        sessionStorage.setItem("tarjeta_num_3", valor.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').split(' ')[2])
        sessionStorage.setItem("tarjeta_num_4", valor.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').split(' ')[3])

        document.getElementById("tarjeta_num_1").value = valor.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').split(' ')[0]
        document.getElementById("tarjeta_num_2").value = valor.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').split(' ')[1]
        document.getElementById("tarjeta_num_3").value = valor.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').split(' ')[2]
        document.getElementById("tarjeta_num_4").value = valor.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').split(' ')[3]
        
    }

    const tarjetaDateOnkeyup = (e) => {
          
        const valor = e.target.value

        const partes = valor.split("-");
        const añoString = partes[0];
        const mesString = partes[1];

        // Convertir el mes de cadena a número
        const mesNumero = parseInt(mesString);

        // Obtener los últimos 2 dígitos del año
        const añoUltimosDosDigitos = añoString.slice(-2);

        sessionStorage.setItem("tarjeta_fecha_cad_1", mesNumero);
        sessionStorage.setItem("tarjeta_fecha_cad_2", añoUltimosDosDigitos);

        // Compobar q le fecha de caducidad es maño de la fecha actual
        const fechaActual = new Date();
        const añoActual = fechaActual.getFullYear();
        const mesActual = fechaActual.getMonth() + 1;

        if (parseInt(añoUltimosDosDigitos) < parseInt(añoActual.toString().slice(-2))) {
            setAlertTitle('Fecha de caducidad');
            setAlertMessage('La fecha de caducidad de la tarjeta es incorrecta.');
            setShowAlert(true);
        }

        if (parseInt(añoUltimosDosDigitos) == parseInt(añoActual.toString().slice(-2)) && mesNumero < mesActual) {
            setAlertTitle('Fecha de caducidad');
            setAlertMessage('La fecha de caducidad de la tarjeta es incorrecta.');
            setShowAlert(true);
        }

    }

    const codigoPostalHandler = (e) => {
        if (e.target.value.length != 5) {
            setAlertTitle('Código postal');
            setAlertMessage('El código postal introducido es incorrecto.');
            setShowAlert(true);
        }
    }

    const campoEditHandler = (e) => {
        sessionStorage.setItem(e.target.name, e.target.value);
    }

    return (
        <>
        <form id="buscador" className='w-full flex justify-center py-[20px]'>
            <div id="paso1" className='w-full'>
                <div className="formulario1 pb-[20px]">
                    <div className='lg:w-[450px] w-full'>
                        <h3 className='text-xl pb-[15px]'>{t("select_dates")}</h3>
                        <div className='flex justify-between lg:flex-row flex-col gap-2'>
                            <div className="w-full">
                                <label htmlFor="fechainiR">{t("entrance")}</label><br />
                                <input type="date" id="fechaini" name="fechaini" required="required" className='flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg text focus:ring-blue-500 focus:border-blue-500 w-full p-2.5'/>
                            </div>
                            <div className="w-full">
                                <label htmlFor="fechafinR" >{t("exit")}</label><br />
                                <input type="date" id="fechafin" name="fechafin" required="required" className='flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg text focus:ring-blue-500 focus:border-blue-500 w-full p-2.5' />
                            </div>
                            <input type="button" value={t("search")} onClick={() => buscarDisponobilidad()} className="text-white bg-box px-[15px] hover:scale-[1.1] rounded-lg mt-[23px] transition btn_siguiente cursor-pointer" />
                        </div>
                    </div>
                </div>
                <div id="respuesta">
                </div>
            </div>
            <div id="paso2" style={{"display": "none"}}>
                <input type="button" className='cursor-pointer hover:underline' value="Atr&aacute;s" onClick={paso1} />
                <div className="formulario2 mt-4">
                    <div className="datos_personales">	
                        <h3>Tus datos</h3>
                        <div className='dato_personal'>
                            <label htmlFor="dato_nombre" className='bg-primary'><GoogleTranslate textToTranslate={"Nombre y apellidos"} /></label>
                            <input type="text" id="dato_nombre" name="dato_nombre" onBlur={campoEditHandler} className='outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 peer pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-box_border dark:ring-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-box_border dark:focus:ring-box_border_dark sm:text-sm sm:leading-6 bg-box dark:bg-box_dark dark:text-white' />
                        </div>
                        <div className='dato_personal'>
                            <label htmlFor="dato_direccion" className='bg-primary'><GoogleTranslate textToTranslate={"Direccion"} /></label>
                            <input type="text" id="dato_direccion" name="dato_direccion" onBlur={campoEditHandler} className='outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 peer pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-box_border dark:ring-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-box_border dark:focus:ring-box_border_dark sm:text-sm sm:leading-6 bg-box dark:bg-box_dark dark:text-white' />
                        </div>
                        <div className='dato_personal'>
                            <label htmlFor="dato_poblacion" className='bg-primary'><GoogleTranslate textToTranslate={"Poblacion"} /></label>
                            <input type="text" id="dato_poblacion" name="dato_poblacion" onBlur={campoEditHandler} className='outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 peer pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-box_border dark:ring-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-box_border dark:focus:ring-box_border_dark sm:text-sm sm:leading-6 bg-box dark:bg-box_dark dark:text-white' />
                        </div>
                        <div className='dato_personal'>
                            <label htmlFor="dato_cp" className='bg-primary'><GoogleTranslate textToTranslate={"Codigo Postal"} /></label>
                            <input type="number" id="dato_cp" name="dato_cp" onBlur={(e) => {
                                codigoPostalHandler(e)
                                campoEditHandler(e)
                                }} 
                                className='outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 peer pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-box_border dark:ring-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-box_border dark:focus:ring-box_border_dark sm:text-sm sm:leading-6 bg-box dark:bg-box_dark dark:text-white'
                            />
                        </div>
                        <div className='dato_personal'>
                            <label htmlFor="dato_provincia" className='bg-primary'><GoogleTranslate textToTranslate={"Provincia"} /></label>
                            <input type="text" id="dato_provincia" name="dato_provincia" onBlur={campoEditHandler} className='outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 peer pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-box_border dark:ring-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-box_border dark:focus:ring-box_border_dark sm:text-sm sm:leading-6 bg-box dark:bg-box_dark dark:text-white' />
                        </div>
                        <div className='dato_personal'>
                            <label htmlFor="dato_pais" className='bg-primary'><GoogleTranslate textToTranslate={"Pais"} />  </label>
                            <input type="text" id="dato_pais" name="dato_pais" onBlur={campoEditHandler} className='outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 peer pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-box_border dark:ring-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-box_border dark:focus:ring-box_border_dark sm:text-sm sm:leading-6 bg-box dark:bg-box_dark dark:text-white' />
                        </div>
                        <div className='dato_personal'>
                            <label htmlFor="dato_telefono" className='bg-primary'><GoogleTranslate textToTranslate={"Telefono"} /></label>
                            <input type="tel" id="dato_telefono" name="dato_telefono" onBlur={campoEditHandler} className='outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 peer pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-box_border dark:ring-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-box_border dark:focus:ring-box_border_dark sm:text-sm sm:leading-6 bg-box dark:bg-box_dark dark:text-white' />
                        </div>
                        <div className='dato_personal'>
                            <label htmlFor="dato_email" className='bg-primary'><GoogleTranslate textToTranslate={"Email"} /></label>
                            <input type="email" id="dato_email" name="dato_email" onBlur={campoEditHandler} className='outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 peer pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-box_border dark:ring-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-box_border dark:focus:ring-box_border_dark sm:text-sm sm:leading-6 bg-box dark:bg-box_dark dark:text-white'/>
                        </div>
                    </div>
                    <div className="tarjeta_credito mt-0" style={{paddingTop: "0px"}}>
                        <h3>Tarjeta de cr&eacute;dito</h3>
                        <div className="campo">
                            <div className='dato_personal mt-0'>
                                <label htmlFor="tarjeta_num_1" className='bg-primary'><GoogleTranslate textToTranslate={"Número de tarjeta"} /></label>
                                <div className="relative w-full">
                                    <input type="text" onChange={(e) => tarjetaOnkeyup(e)} className="outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 peer pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-box_border dark:ring-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-box_border dark:focus:ring-box_border_dark sm:text-sm sm:leading-6 bg-box dark:bg-box_dark dark:text-white" style={{"marginTop": 0}} placeholder="" pattern="(\d{4}[- ]){3}\d{4}" required />
                                    <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                                        {obtenerTipoTarjeta(nTargeta)}
                                    </div>
                                </div>
                                <input name="tarjeta_num_1" id="tarjeta_num_1" type='hidden'/>  
                                <input  name="tarjeta_num_2" id="tarjeta_num_2" type='hidden'/>
                                <input name="tarjeta_num_3" id="tarjeta_num_3" type='hidden'/>
                                <input name="tarjeta_num_4" id="tarjeta_num_4" type='hidden'/>
                            </div>
                        </div>
                        <div className="campo">
                            <div className='dato_personal'>
                                <label htmlFor="tarjeta_fecha_cad_1" className='bg-primary'><GoogleTranslate textToTranslate={"Caducidad"} /></label>
                                <div className="tarjetainline w-full flex items-center">
                                    <input onChange={tarjetaDateOnkeyup} type="month" className="outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none peer pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-box_border dark:ring-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-box_border dark:focus:ring-box_border_dark sm:text-sm sm:leading-6 bg-box dark:bg-box_dark dark:text-white mt-0"  style={{"marginTop": 0, "paddingLeft": "5px"}}  placeholder="mm/yy" required />
                                </div>
                                {/*
                                <div className="tarjetainline w-full flex items-center">
                                    <input style={{marginTop: "3px", width: "100%"}} className='outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 peer pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-box_border dark:ring-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-box_border dark:focus:ring-box_border_dark sm:text-sm sm:leading-6 bg-box dark:bg-box_dark dark:text-white' type="number" ref={inputTargetaDateRefs.current[0]} name="tarjeta_fecha_cad_1" id="tarjeta_fecha_cad_1" onBlur={campoEditHandler} onChange={(e) => tarjetaDateOnkeyup(0, e)}/>
                                    <span style={{marginTop: "3px"}}>/</span>
                                    <input style={{marginTop: "3px", width: "100%"}} className='outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 peer pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-box_border dark:ring-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-box_border dark:focus:ring-box_border_dark sm:text-sm sm:leading-6 bg-box dark:bg-box_dark dark:text-white' type="number" ref={inputTargetaDateRefs.current[1]} name="tarjeta_fecha_cad_2" id="tarjeta_fecha_cad_2" onBlur={campoEditHandler} onChange={(e) => tarjetaDateOnkeyup(1, e)}/> 
                                </div>
                                */}
                            </div>
                        </div>  
                        <div className="campo w-full">
                            <div className='dato_personal w-full'>
                                <label htmlFor="tarjeta_cvv" className='bg-primary'>CVV</label>
                                <div className="tarjetainline w-full">
                                    <input style={{marginTop: "3px", width: "100%", "paddingLeft": "5px"}} className='outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 peer pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-box_border dark:ring-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-box_border dark:focus:ring-box_border_dark sm:text-sm sm:leading-6 bg-box dark:bg-box_dark dark:text-white' type="number" onBlur={campoEditHandler} maxLength={4} minLength={3}  name="tarjeta_cvv" id="tarjeta_cvv"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>	
                <div id="paso2_button"></div>
            </div>
            <div id="paso3" style={{"display": "none"}}>
                <input type="button" value="Atr&aacute;s" />
                <div id="resumen">
                </div>
                <div id="legal">
                    <input type="checkbox" id="acepto_condiciones" name="acepto_condiciones" required="required" /> Acepto la
                    <a href='#' id='proteccion_datos'>Protección de datos</a> y la
                    <a href='#' id='politica_cancelacion'>Política de cancelación</a>
                </div>
                <div id="paso3_button">
                </div>
            </div>
            <div id="paso4" style={{"display": "none"}}>
                <div id="confirmacion">
                </div>
            </div>
        </form>
        <form id="redsys" name="redsys" action="https://sis.redsys.es/sis/realizarPago" method="POST">
            <input type="hidden" id="Ds_SignatureVersion" name="Ds_SignatureVersion" value="" />
            <input type="hidden" id="Ds_MerchantParameters" name="Ds_MerchantParameters" value="" />
            <input type="hidden" id="Ds_Signature" name="Ds_Signature" value="" />
        </form>
        </>
    )
}