import React, { useEffect } from 'react'
import { GoogleTranslate } from '../components';
import { save_page_view } from '../contexts/analytics';

export default function CookiesPolicy() {

  const nombre_fiscal = "Abelardo Pérez Monteavaro"
  const dominio_web = "https://luarcahotelzabala.com/"

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    window.document.title = "Luarca Zabala Hotel - Politica de Cookies"
    save_page_view("Política de Cookies");
  }, [])

  return (
    <>
      <h3 className='text-lg'>{nombre_fiscal} <GoogleTranslate textToTranslate={"informa acerca del uso de las cookies en su página web"} />: {dominio_web}</h3>
      <h2 className='text-xl mt-4'><GoogleTranslate textToTranslate={"¿Qué son las cookies?"} /></h2>
      <p className='mt-4'><GoogleTranslate textToTranslate={"Las cookies son archivos que se pueden descargar en su equipo a través de las páginas web."} /> <GoogleTranslate textToTranslate={"Son herramientas que tienen un papel esencial para la prestación de numerosos servicios de la sociedad de la información."} /><GoogleTranslate textToTranslate={" Entre otros, permiten a una página web almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información obtenida, se pueden utilizar para reconocer al usuario y mejorar el servicio ofrecido."}/></p>
      <h2 className='my-4 text-xl'><GoogleTranslate textToTranslate={"Tipos de cookies"} /></h2>
      <p><GoogleTranslate textToTranslate={"Según quien sea la entidad que gestione el dominio desde donde se envían las cookies y trate los datos que se obtengan se pueden distinguir dos tipos"} />:</p>
      <br />
      <ul className='*:mb-4'>
        <li><GoogleTranslate textToTranslate={"Cookies propias: aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario."} /></li>
        <li><GoogleTranslate textToTranslate={"Cookies de terceros: aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies."} /></li>
      </ul>
      <br />
      <p>
        <GoogleTranslate textToTranslate={"En el caso de que las cookies sean instaladas desde un equipo o dominio gestionado por el propio editor pero la información que se recoja mediante éstas sea gestionada por un tercero, no pueden ser consideradas como cookies propias."} />
      </p>
      <br />
      <p><GoogleTranslate textToTranslate={"Existe también una segunda clasificación según el plazo de tiempo que permanecen almacenadas en el navegador del cliente, pudiendo tratarse de"} />: </p>
      <br />
      <ul className='*:mb-3'>
        <li><p><GoogleTranslate textToTranslate={"Cookies de sesión: diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. "} /><GoogleTranslate textToTranslate={"Se suelen emplear para almacenar información que solo interesa conservar para la prestación del servicio solicitado por el usuario en una sola ocasión (p.e. una lista de productos adquiridos)."} /></p></li>
        <li><p><GoogleTranslate textToTranslate={"Cookies persistentes: los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años."} /></p></li>
      </ul>
      <br />
      <p><GoogleTranslate textToTranslate={"Por último, existe otra clasificación con cinco tipos de cookies según la finalidad para la que se traten los datos obtenidos:"} /></p>
      <br />
      <ul className='*:mb-3'>
        <li>
          <p><GoogleTranslate textToTranslate={"Cookies técnicas: aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de vídeos o sonido o compartir contenidos a través de redes sociales."}/></p>
        </li>
        <li>
          <p><GoogleTranslate textToTranslate={"Cookies de personalización: permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc."}/></p>
        </li>
        <li>
          <p><GoogleTranslate textToTranslate={"Cookies de análisis: permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio"}/>.</p>
        </li>
        <li>
          <p>Cookies publicitarias: permiten la gestión, de la forma más eficaz posible, 
          de los espacios publicitarios. </p>
        </li>
        <li>
          <p>Cookies de publicidad comportamental: almacenan información del comportamiento 
          de los usuarios obtenida a través de la observación continuada de sus hábitos 
          de navegación, lo que permite desarrollar un perfil específico para mostrar 
          publicidad en función del mismo. </p>
        </li>
        <li>
          <p>Cookies de redes sociales externas: se utilizan para que los visitantes 
          puedan interactuar con el contenido de diferentes plataformas sociales (facebook, 
          youtube, twitter, linkedIn, etc..) y que se generen únicamente para los usuarios 
          de dichas redes sociales. Las condiciones de utilización de estas cookies 
          y la información recopilada se regula por la política de privacidad de la 
          plataforma social correspondiente. </p>
        </li>
      </ul>
      <h2 className='my-4 text-xl'>Desactivación y eliminación de cookies </h2>  
      <p>
        <p>Tienes la opción de permitir, bloquear o eliminar las cookies instaladas en tu 
        equipo mediante la configuración de las opciones del navegador instalado en su 
        equipo. Al desactivar cookies, algunos de los servicios disponibles podrían dejar 
        de estar operativos. La forma de deshabilitar las cookies es diferente para cada 
        navegador, pero normalmente puede hacerse desde el menú Herramientas u Opciones. 
        También puede consultarse el menú de Ayuda del navegador dónde puedes encontrar 
        instrucciones. El usuario podrá en cualquier momento elegir qué cookies quiere 
        que funcionen en este sitio web.</p>
      </p>  
      <br />
      <p>Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador:</p> 
      <br />
      <ul className='*:mb-3'>
        <li>Microsoft Internet Explorer o Microsoft Edge: http://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies</li>
        <li>Mozilla Firefox: http://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia</li>
        <li>Chrome: https://support.google.com/accounts/answer/61416?hl=es</li>
        <li>Safari: http://safari.helpmax.net/es/privacidad-y-seguridad/como-gestionar-las-cookies/</li>
        <li>Opera: http://help.opera.com/Linux/10.60/es-ES/cookies.html</li>
      </ul>
      <br />
      <p>Además, también puede gestionar el almacén de cookies en su navegador a través de herramientas como las siguientes </p>
      <br />
      <ul className='*:mb-3'>
        <li> Ghostery: www.ghostery.com/ </li>
        <li> Your online choices: www.youronlinechoices.com/es/</li>
      </ul>
      <br />
      <p>
        La aceptación de la Política de cookies {dominio_web} asume que usted acepta 
        el uso de cookies. No obstante, muestra información sobre su Política de cookies 
        en la parte inferior o superior de cualquier página del portal con cada inicio 
        de sesión con el objeto de que usted sea consciente.
      </p>
      <br />
      <p>Ante esta información es posible llevar a cabo las siguientes acciones:</p> 
      <br />
      <ul className='*:mb-3'>
        <li><p>Aceptar cookies. No se volverá a visualizar este aviso al acceder a cualquier 
          página del portal durante la presente sesión.</p></li>
        <li><p>Cerrar. Se oculta el aviso en la presente página.</p></li>
        <li><p>Modificar su configuración. Podrá obtener más información sobre qué son 
          las cookies, conocer la Política de cookies de {dominio_web} y modificar 
          la configuración de su navegador.</p></li>
      </ul>
      <br />
      <h2 className="my-4 text-xl">Cookies utilizadas en {dominio_web}</h2>
      <p>A continuación se identifican las cookies que están siendo utilizadas en este portal así como su tipología y función:</p>
      <br />
      <p>
        <strong>Cookies de análisis</strong>: Son aquéllas que bien tratadas por nosotros 
        o por terceros, nos permiten cuantificar el número de usuarios y así realizar 
        la medición y análisis estadístico de la utilización que hacen los usuarios del 
        servicio ofertado. Para ello se analiza su navegación en nuestra página web con 
        el fin de mejorar la oferta de productos o servicios que le ofrecemos. (Google 
        Analytics)
      </p>
      <br />
      <p>
        <strong>Cookies de personalización</strong>: Son aquellas que permiten al usuario 
        acceder al servicio con algunas características de carácter general predefinidas 
        en función de una serie de criterios en el terminal del usuario como por ejemplo 
        serian el idioma o el tipo de navegador a través del cual se conecta al servicio. 
        (Google Translate)
      </p>
      <br />
      <p>
        <strong>Cookies técnicas</strong>: Son aquellas que permiten al usuario la navegación 
        amigable y la utilización de sus diferentes funciones. (Twitter, Twing, Gstaic, 
        Propias)
      </p>  
  </>
  )
}
