import React, { useState } from 'react'

import "./consulta.css"
import { registrar_evento } from '../../contexts/analytics'

export default function Consulta({className}) {

    const [pin, setPin] = useState(0)
    const [codigo, setCodigo] = useState(0)

    function consultar() {

        registrar_evento("Consultar Reserva")

        var url = `https://api.lemurgh.com/reservas/v3/api.php?action=consulta&usuario=zabala&codigo=${codigo}&pin=${pin}`;
        console.log(url)
        var ajax = new XMLHttpRequest();
        ajax.open('GET', url, true);
        ajax.onreadystatechange = function() {
            if (ajax.readyState == 4 && ajax.status == 200) {
                document.getElementById('respuesta_consultar_reserva').innerHTML = ajax.responseText + `<script>gtag('event', 'comprobar_reserva')</script>`
            }
        }
        ajax.send();
    
    }

    const pinHandler = (e) => {
        setPin(e.target.value)    
    }

    const codigoHandler = (e) => {
        setCodigo(e.target.value)
    }

    return (
        <>
            <section className={className}>
                <div id="consulta" className='w-full'>
                    <div id="consulta1" className='mb-5 flex justify-center'>
                        <div className="formulario2 w-[450px] lg:w-full">
                            <div className='lg:w-[450px] w-full'>
                                <h3 className='text-xl pb-[15px]'>Consultar reserva</h3>
                                <div className='flex justify-between lg:flex-row flex-col gap-2 items-center'>
                                    <div className="campo w-full">
                                        <label htmlFor="codigo" className='sr-only'>C&oacute;digo reserva</label>
                                        <input onChange={codigoHandler} type="text" id="codigo" name="codigo" inputMode="tel" placeholder='Codigo Reserva' required="required" className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg text focus:ring-blue-500 focus:border-blue-500 flex items-center w-full p-2.5'/>
                                    </div>
                                    <div className="campo w-full">
                                        <label htmlFor="pin" className='sr-only'>PIN</label>
                                        <input onChange={pinHandler} type="text" id="pin" name="pin" inputMode="tel" placeholder='PIN' required="required" className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg text focus:ring-blue-500 focus:border-blue-500 flex items-center w-full p-2.5'/>
                                    </div>
                                    <div className="campo w-full">
                                        <button onClick={consultar} className="text-white bg-box px-[15px] pt-[18px] hover:scale-[1.1] rounded-lg transition-all cursor-pointer btn_siguiente">Consultar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='respuesta_consultar_reserva'>

            </section>
        </>
    )
}

/*

<h3 className='text-xl pb-[15px]'>Consultar reserva</h3>
                                <div className='flex justify-between lg:flex-row flex-col gap-2'>
                                    <div className="campo">
                                    <label htmlFor="codigo" className='sr-only'>C&oacute;digo reserva</label>
                                        <input onChange={codigoHandler} type="text" id="codigo" name="codigo" inputMode="tel" placeholder='Codigo Reserva' required="required" className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg text focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'/>
                                    </div>
                                    <div className="campo">
                                        <label htmlFor="pin" className='sr-only'>PIN</label>
                                        <input onChange={pinHandler} type="text" id="pin" name="pin" inputMode="tel" placeholder='PIN' required="required" className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg text focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'/>
                                    </div>
                                    <div className="campo">
                                        <button onClick={consultar} className="text-white bg-box px-[15px] pt-[18px] hover:scale-[1.1] rounded-lg mt-[23px] transition-all cursor-pointer btn_siguiente">Consultar</button>
                                    </div>
                                </div>

    <div className={className} >
            <form id="consulta">
                <div id="consulta1">
                    <div className="formulario2">
                        <div className="consulta w-[450px]">
                            <h3 className='text-xl pb-[15px]'>Consultar reserva</h3>
                            <div className='flex justify-between'>
                                <div className="campo">
                                    <label htmlFor="codigo" className='sr-only'>C&oacute;digo reserva</label>
                                    <input type="text" id="codigo" name="codigo" inputMode="tel" placeholder='Codigo Reserva' required="required" className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg text focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5'/>
                                </div>  
                                <div className="campo">
                                    <label htmlFor="pin" className='sr-only'>PIN</label>
                                    <input type="text" id="pin" name="pin" inputMode="tel" placeholder='PIN' required="required" className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg text focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5'/>
                                </div>
                                <input type="button" value="Consultar" onClick="consultar()" className="text-white bg-box px-[15px] hover:scale-[1.1] rounded-lg mt-[23px] transition-all cursor-pointer btn_consulta"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="respuesta_consulta">
                </div>
            </form>
        </div>
    */