import React, { useState } from "react";

import { Header, Footer, Cookies, Consulta, Alerta, Chat } from "./components";
import { Routes, Route } from "react-router-dom";
import { Home, PageNotFound, Galeria, Bookings, Contacto, PrivacyPolicy, CookiesPolicy, LegalWarning, Servicios, Localizacion, MiniBar, CheckIn } from "./pages";

import { Toaster } from 'sonner'

import { useStateContext } from './contexts/ContextProvider'
import { useEffect } from "react";
import { registrar_device } from "./contexts/analytics";

function App() {

  const { showAlert } = useStateContext();

  useEffect(() => {
    
    const userAgent = window.navigator.userAgent;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    const isTablet = /iPad/i.test(userAgent);
    const isDesktop = !isMobile && !isTablet;

    if (isMobile) {
      registrar_device("Movil")
    } else if (isTablet) {
      registrar_device("Table")
    } else if (isDesktop) {
      registrar_device("Ordenador")
    } else {
      registrar_device("Desconocido")
    }

  }, [])

  return (
    <>
      <Header />
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 z-10 py-5 ">
      <Toaster richColors expand={false} duration={4000} position="top-center" className="block lg:hidden" />
      <Toaster richColors expand={true} duration={4000} className="lg:block hidden"/>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<Galeria />} />
          <Route path="/bookings" element={<Bookings />} />
          <Route path="/contact" element={<Contacto />} />
          {/*<Route path="/rates" element={<Tarifas />} />*/}
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/location" element={<Localizacion />} />
          <Route path="/minibar" element={<MiniBar />} />
          {/*<Route path="/bedrooms" element={<Habitaciones />} />*/}
          <Route path="/consulta" element={<Consulta className={"flex justify-center w-full"} />} />
          <Route path="/legal_warning" element={<LegalWarning />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/cookies_policy" element={<CookiesPolicy />} />    
          <Route path="/check-in" element={<CheckIn />} />
          <Route path="/status" element={<iframe src="https://luarcazabalahotel.betteruptime.com" className="w-full h-[725px] m-2 bg-box rounded-lg border" />} />      
          <Route path="/*" element={<PageNotFound />} />
          <Route path="**" element={<PageNotFound />} />  
        </Routes>
      </main>
      <Cookies />
      {
        showAlert && <Alerta />
      }
      <Footer />
      {/*
      <Chat className={"w-[400px] bottom-2 right-2"} />
      */}
    </>
  );
}

export default App;
