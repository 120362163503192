import React from 'react'

import { useStateContext } from '../contexts/ContextProvider'
import GoogleTranslate from './GoogleTranslate';
import { IconoZabala } from '../resources'
import { registrar_evento } from '../contexts/analytics';

const spanStyle = {
    border: "0px",
    clip: "rect(0px, 0px, 0px, 0px)",
    height: "1px",
    margin: "-1px",
    overflow: "hidden",
    padding: "0px",
    position: "fixed",
    whiteSpace: "nowrap",
    width: "1px",
    top: "0px",
    left: "0px"
}

export default function Alerta() {

    const { alertTitle, alertMessage, setShowAlert } = useStateContext();

    return (
        <div style={{position: "fixed", overflow: "auto", inset: "0px"}} className='z-30'>
            <span tabIndex="0" aria-hidden="true" data-floating-ui-focus-guard="" data-aria-hidden="true" style={spanStyle}></span>
            <div className="grid place-items-center fixed w-screen h-screen bg-black bg-opacity-60 backdrop-blur-sm" style={{opacity: "1"}}>
                <div tabIndex="-1" className="relative bg-box dark:bg-body_dark text-black dark:text-white dark:border dark:border-white m-4 rounded-lg shadow-2xl text-blue-gray-500 antialiased font-sans text-base font-light leading-relaxed w-full lg:w-2/5 2xl:w-1/4 min-w-[80%] md:min-w-[60%] lg:min-w-[40%] 2xl:min-w-[25%] max-w-[80%] md:max-w-[60%] lg:max-w-[40%]" aria-labelledby=":R54b9km:-label" aria-describedby=":R54b9km:-description" id=":R54b9kmH1:" role="dialog" style={{opacity: "1", transform: "none"}}>
                    <div className="relative flex items-center justify-between pl-5 h-[50px]">
                        <img src={IconoZabala} alt='Logo Luarca Zabala Hotel' className='h-[50px] py-2' />   
                        {/*<span className='w-full pl-2'>{process.env.REACT_APP_KEY_PRUEBA}</span>*/}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="absolute right-5 h-8 w-8 p-[4px] hover:bg-gray-900/10 rounded-lg cursor-pointer" onClick={() => {
                                setShowAlert(false)
                                registrar_evento("Cerrar Alerta")
                            }
                        }>
                            <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd"></path>
                        </svg>
                    </div>
                    <div className="relative p-4 text-blue-gray-500 antialiased font-sans text-base font-light leading-relaxed grid place-items-center gap-4">
                        <h4 className="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900"><GoogleTranslate textToTranslate={alertTitle} /></h4>
                        <p className="block antialiased font-sans text-base leading-relaxed text-inherit text-center font-normal"><GoogleTranslate textToTranslate={alertMessage} /></p>
                    </div>
                    <div className="items-center shrink-0 flex-wrap p-4 text-blue-gray-500 flex justify-center space-x-2">
                        {/*
                        <button className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg text-gray-900 hover:bg-gray-900/10 active:bg-gray-900/20" type="button">cancel</button>
                        <button className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85]" type="button">Got it</button>
                        */}
                    </div>
                </div>
            </div>
            <span tabIndex="0" aria-hidden="true" data-floating-ui-focus-guard="" data-aria-hidden="true" style={spanStyle}></span>
        </div>
    )
}
