import React from 'react'

import { Typography } from "@material-tailwind/react";
import { MdArrowOutward } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function OtrosEstablecimientos() {

    const { t } = useTranslation();

    return (
        <>
            <h2 className='flex items-end text-primary pr-5 text-3xl mb-5'>{t("other_establishments")}</h2>
            <div className='flex flex-col md:flex-row gap-6 justify-around'>
                <figure className="relative h-96 w-full">
                    <img
                        className="h-full w-full rounded-xl object-cover object-center"
                        src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/470577050.jpg?k=d93e23070e35faa9a94cb765db96ad0ffe03c7417db76ddb28333bce971b986c"
                        alt="Apatermento Luarca Experience"
                    />
                    <a href='https://luarcaexperience.com' target='_blank'>
                        <figcaption className="group hover:scale-105 cursor-pointer transition-all absolute bottom-3 left-2/4 flex w-[calc(100%-20px)] md:w-[calc(100%-4rem)] -translate-x-2/4 justify-between rounded-xl border border-white bg-white/50 py-4 px-6 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                            <div>
                            <Typography variant="h5" color="blue-gray">
                                Luarca Experience
                            </Typography>
                            <Typography color="gray" className="mt-2 font-normal hidden md:block">
                                {t("apartment")}
                            </Typography>
                            </div>
                            <span className='flex items-center  md:hidden group-hover:block'><MdArrowOutward className='md:size-6' /></span>
                        </figcaption>
                    </a>
                </figure>
                <figure className="relative h-96 w-full">
                    <img
                        className="h-full w-full rounded-xl object-cover object-center"
                        src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/151599147.jpg?k=7ae21be779f9c338ed5176393b3848837a3346a8a1a4422bdedf8143f349a728&o=&hp=1"
                        alt="Apartamentos Rurales Playas de Luarca"
                    />
                    <a href='https://playasdeluarca.com/' target='_blank'>
                        <figcaption className="group hover:scale-105 cursor-pointer transition-all absolute bottom-3 left-2/4 flex w-[calc(100%-20px)] md:w-[calc(100%-4rem)] -translate-x-2/4 justify-between rounded-xl border border-white bg-white/50 py-4 px-6 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                            <div>
                            <Typography variant="h5" color="blue-gray">
                                Playas de Luarca
                            </Typography>
                            <Typography color="gray" className="mt-2 font-normal hidden md:block">
                                {t("rural_apartments")}
                            </Typography>
                            </div>
                            <span className='flex items-center md:hidden group-hover:block'><MdArrowOutward className='md:size-6' /></span>
                        </figcaption>
                    </a>
                </figure>
            </div>
        </>
    )
}
