import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GoogleTranslate } from '../components';
import { save_page_view } from '../contexts/analytics';

export default function PageNotFound() {

    const { t } = useTranslation();


    const goBack = () => {
        window.history.back();
    };


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        window.document.title = "Luarca Zabala Hotel - Pagina no encontrada" 
        save_page_view("Pagina no encontrada");
    }, [])

    return (
        <section className="">
            <div className="container flex items-center justify-center px-6 py-12 mx-auto">
                <div className="w-full ">
                    <div className="flex flex-col items-center max-w-lg mx-auto text-center">
                        <p className="text-sm font-medium text-primary">404 error</p>
                        <h1 className="mt-3 text-2xl font-semibold text-gray-800 md:text-3xl">{t("we_lost_this_page")}</h1>
                        <p className="mt-4 text-gray-500">{t("we_lost_this_page_text")}</p>

                        <div className="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
                            <button onClick={goBack} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-00gap-x-2 sm:w-auto ">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 rtl:rotate-180">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                                </svg>
                                <span>{t("return_back")}</span>
                            </button>

                            <Link 
                            to={`/`}
                            className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-primary rounded-lg shrink-0 sm:w-auto"
                            >
                                {t("return_home")}
                            </Link>
                        </div>
                    </div>
                    <div className="grid w-full max-w-6xl grid-cols-1 gap-8 mx-auto mt-8 sm:grid-cols-1 lg:grid-cols-2">
                        {/*
                        <div className="p-6 rounded-lg bg-green-100">
                            <span className="text-gray-500">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>
                            </span>
                            
                            <h3 className="mt-6 font-medium text-gray-700">Documentation</h3>

                            <p className="mt-2 text-gray-500 ">Dive in to learn all about our product.</p>

                            <a href="#" className="inline-flex items-center mt-4 text-sm text-primary gap-x-2 hover:underline">
                                <span>Start learning</span>

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 rtl:rotate-180">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                </svg>
                            </a>
                        </div>
                        */}
                        <div className="p-6 rounded-lg bg-green-100">
                            <span className="text-gray-500">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                                </svg>
                            </span>
                            
                            <h3 className="mt-6 font-medium text-gray-700"><GoogleTranslate textToTranslate={"Reserva"} /></h3>

                            <p className="mt-2 text-gray-500 "><GoogleTranslate textToTranslate={"Realiza tu reserva desde la web"} /></p>

                            <Link to="bookings"  className="inline-flex items-center mt-4 text-sm text-primary gap-x-2 hover:underline">
                                <span><GoogleTranslate textToTranslate={"Haz tu reserva"} /></span>

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 rtl:rotate-180">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                </svg>
                            </Link>
                        </div>
                       
                        <div className="p-6 rounded-lg bg-green-100">
                            <span className="text-gray-500">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z" />
                                </svg>
                            </span>
                            
                            <h3 className="mt-6 font-medium text-gray-700"><GoogleTranslate textToTranslate={"Contactanos"} /></h3>

                            <p className="mt-2 text-gray-500 "><GoogleTranslate textToTranslate={"No encuentras lo que buscas"} /></p>

                            <Link to="contact" className="inline-flex items-center mt-4 text-sm text-primary gap-x-2 hover:underline">
                                <span><GoogleTranslate textToTranslate={"Contactanos"} /></span>

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 rtl:rotate-180">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                </svg>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
